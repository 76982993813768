import React from "react";
import { Route } from "react-router-dom";
import Resources from "../libraries/resources";

export const PrivateRoute = ({ component: Component, customParams: CustomParams, ...rest }) => {

  CustomParams = CustomParams || {};

  return (<Route
    {...rest}
    render={props => {
      const parseJwt = (token) => {
        try {
          return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
          return null;
        }
      };

      // get token & userInformation
      var token = localStorage.getItem("adminToken");
      var userInformation = localStorage.getItem('adminUserInfo');

      if (token && userInformation) {
        // parse token & information
        var parsedToken = parseJwt(token);
        var parsedUserInformation = JSON.parse(userInformation);

        // token security control
        if (parsedToken && parsedUserInformation && parsedToken.unique_name === parsedUserInformation.email && parsedToken.sub == parsedUserInformation.id) {
          return <Component {...props} />;
        } else {
          window.location.href = Resources.getValue('appBaseEnvURL') + "/#/login";
        }
      } else {
        window.location.href = Resources.getValue('appBaseEnvURL') + "/#/login";
      }
    }}
  />);
};

export default PrivateRoute;
