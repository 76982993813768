export const couponConstants = {
  GET_ALL_COUPON_REQUEST: "GET_ALL_COUPON_REQUEST",
  GET_ALL_COUPON_SUCCESS: "GET_ALL_COUPON_SUCCESS",
  GET_ALL_COUPON_FAILURE: "GET_ALL_COUPON_FAILURE",

  GET_COUPON_BY_ID_REQUEST: "GET_COUPON_BY_ID_REQUEST",
  GET_COUPON_BY_ID_SUCCESS: "GET_COUPON_BY_ID_SUCCESS",
  GET_COUPON_BY_ID_FAILURE: "GET_COUPON_BY_ID_FAILURE",

  ADD_COUPON_REQUEST: "ADD_COUPON_REQUEST",
  ADD_COUPON_SUCCESS: "ADD_COUPON_SUCCESS",
  ADD_COUPON_FAILURE: "ADD_COUPON_FAILURE",

  UPDATE_COUPON_REQUEST: "UPDATE_COUPON_REQUEST",
  UPDATE_COUPON_SUCCESS: "UPDATE_COUPON_SUCCESS",
  UPDATE_COUPON_FAILURE: "UPDATE_COUPON_FAILURE",

  DELETE_COUPON_REQUEST: "DELETE_COUPON_REQUEST",
  DELETE_COUPON_SUCCESS: "DELETE_COUPON_SUCCESS",
  DELETE_COUPON_FAILURE: "DELETE_COUPON_FAILURE",

  GET_USER_BY_NAME_REQUEST: "GET_USER_BY_NAME_REQUEST",
  GET_USER_BY_NAME_SUCCESS: "GET_USER_BY_NAME_SUCCESS",
  GET_USER_BY_NAME_FAILURE: "GET_USER_BY_NAME_FAILURE",

  CHANGE_COUPON_REQUEST: "CHANGE_COUPON_REQUEST",
  CHANGE_COUPON_SUCCESS: "CHANGE_COUPON_SUCCESS",
  CHANGE_COUPON_FAILURE: "CHANGE_COUPON_FAILURE",
};
