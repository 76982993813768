import { userConstants } from "../constants";
import { userService } from "../services";
import { history } from "../helpers";
import { modalActions } from "./";
import Languages from "../libraries/languages";
import Resources from "../libraries/resources";

export const userActions = {
  login,
  logout,
  userAdd,
  addUserToCorporation,
  getAllUsers,
  getAllUserTypes,
  getUserById,
  makeUserActivePassive,
  updateUser,
  updateUserTimeExtension,
  confirmUserAsync,
  forgotPasswordAsyc,
};

function login(email, password) {
  return (dispatch) => {
    dispatch(request({ email, password }));

    userService
      .login(email, password)
      .then(
        (user) => {
          dispatch(success(user));
          history.push("/");
        },
        (error) => {
          if (error) {
            dispatch(modalActions.warning(""));
            dispatch(failure(error.toString()));
            dispatch(modalActions.warning(error));
          }
        }
      )
      .catch(() => {
        dispatch(modalActions.error(""));
        dispatch(modalActions.error(Languages.getValue("errorOccuredMsgTxt")));
      });
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  return (dispatch) => {
    dispatch(request());

    userService
      .logout()
      .then(() => {
        dispatch(success());
        window.location.href = Resources.getValue("appBaseEnvURL") + "/#/login";
      })
      .catch((error) => {
        dispatch(modalActions.error(""));
        dispatch(failure(error.toString()));
        dispatch(modalActions.error(Languages.getValue("errorOccuredMsgTxt")));
      });
  };

  function request() {
    return { type: userConstants.LOGOUT_REQUEST };
  }
  function success() {
    return { type: userConstants.LOGOUT_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.LOGOUT_FAILURE, error };
  }
}

function getAllUsers() {
  return (dispatch) => {
    dispatch(request());

    userService.getAllUsers().then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(modalActions.error(Languages.getValue(error)));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.GETALL_USERS_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETALL_USERS_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALL_USERS_FAILURE, error };
  }
}

function getAllUserTypes() {
  return (dispatch) => {
    dispatch(request());

    userService.getAllUserTypes().then(
      (types) => {
        dispatch(success(types.data));
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(modalActions.error(Languages.getValue(error)));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.GET_ALL_USER_TYPES_REQUEST };
  }
  function success(types) {
    return { type: userConstants.GET_ALL_USER_TYPES_SUCCESS, types };
  }
  function failure(error) {
    return { type: userConstants.GET_ALL_USER_TYPES_FAILURE, error };
  }
}

function makeUserActivePassive(id, isActive) {
  return (dispatch) => {
    dispatch(request());

    userService.makeUserActivePassive(id, isActive).then(
      () => {
        dispatch(modalActions.success(""));
        dispatch(success());
        dispatch(
          modalActions.success(
            Languages.getValue("userStatusUpdatedSuccessMsgTxt")
          )
        );
        dispatch(getAllUsers());
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(modalActions.error(Languages.getValue("errorOccuredMsgTxt")));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.ACTIVE_PASSIVE_REQUEST };
  }
  function success() {
    return { type: userConstants.ACTIVE_PASSIVE_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.ACTIVE_PASSIVE_FAILURE, error };
  }
}

function getUserById(id) {
  return (dispatch) => {
    dispatch(request());

    userService.getUserById(id).then(
      (userInfo) => {
        dispatch(success(userInfo.user));
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(modalActions.error(Languages.getValue("errorOccuredMsgTxt")));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.GET_USER_REQUEST };
  }
  function success(detailedUser) {
    return { type: userConstants.GET_USER_SUCCESS, detailedUser };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_FAILURE, error };
  }
}

function updateUser(user) {
  return (dispatch) => {
    dispatch(request());

    userService.updateUser(user).then(
      (user) => {
        dispatch(modalActions.success(""));
        dispatch(success(user));
        dispatch(
          modalActions.success(Languages.getValue("updateActionSuccessMsgTxt"))
        );
        window.location.href = Resources.getValue("appBaseEnvURL") + "/#/users";
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(modalActions.error(Languages.getValue("errorOccuredMsgTxt")));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.USER_UPDATE_REQUEST };
  }
  function success(updatedUser) {
    return { type: userConstants.USER_UPDATE_SUCCESS, updatedUser };
  }
  function failure(error) {
    return { type: userConstants.USER_UPDATE_FAILURE, error };
  }
}

// kurum admin in foramind kullanicisi eklemesi icin & super admin in kuruma foramind kullanicisi eklemesi icin
function userAdd(addedUser) {
  return (dispatch) => {
    dispatch(request(addedUser));

    userService.userAdd(addedUser).then(
      (addedUser) => {
        dispatch(modalActions.success(""));
        dispatch(success(addedUser));
        dispatch(
          modalActions.success(Languages.getValue("addedActionSuccessMsgTxt"))
        );
        window.location.href = Resources.getValue("appBaseEnvURL") + "/#/users";
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(failure(error.toString()));
        dispatch(modalActions.error(error));
      }
    );
  };

  function request(addedUser) {
    return { type: userConstants.ADD_USER_REQUEST, addedUser };
  }
  function success(addedUser) {
    return { type: userConstants.ADD_USER_SUCCESS, addedUser };
  }
  function failure(error) {
    return { type: userConstants.ADD_USER_FAILURE, error };
  }
}

// super admin kuruma admin eklemesi icin
function addUserToCorporation(addedUser) {
  return (dispatch) => {
    dispatch(request(addedUser));

    userService.addUserToCorporation(addedUser).then(
      (addedUser) => {
        dispatch(modalActions.success(""));
        dispatch(success(addedUser));
        dispatch(
          modalActions.success(Languages.getValue("addedActionSuccessMsgTxt"))
        );
        window.location.href = Resources.getValue("appBaseEnvURL") + "/#/users";
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(failure(error.toString()));
        dispatch(modalActions.error(error));
      }
    );
  };

  function request(addedUser) {
    return { type: userConstants.ADD_USER_TO_COMPANY_REQUEST, addedUser };
  }
  function success(addedUser) {
    return { type: userConstants.ADD_USER_TO_COMPANY_SUCCESS, addedUser };
  }
  function failure(error) {
    return { type: userConstants.ADD_USER_TO_COMPANY_FAILURE, error };
  }
}

// kullanıcı süre uzatma
function updateUserTimeExtension(addData) {
  return (dispatch) => {
    dispatch(request(addData));
    userService.updateUserTimeExtension(addData).then(
      (addData) => {
        dispatch(modalActions.success(""));
        dispatch(success(addData));
        dispatch(
          modalActions.success(Languages.getValue("updateActionSuccessMsgTxt"))
        );
        // window.location.href = Resources.getValue('appBaseEnvURL') + '/#/time';
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(failure(error.toString()));
        dispatch(modalActions.error(error));
      }
    );
  };

  function request(addData) {
    return { type: userConstants.UPDATE_USER_TO_TRIAL_DAY_REQUEST, addData };
  }
  function success(addData) {
    return { type: userConstants.UPDATE_USER_TO_TRIAL_DAY_SUCCESS, addData };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_USER_TO_TRIAL_DAY_FAILURE, error };
  }
}

function confirmUserAsync(addUserEmail) {
  return (dispatch) => {
    dispatch(request());

    userService.confirmUserAsync(addUserEmail).then(
      () => {
        dispatch(modalActions.success(""));

        dispatch(success(addUserEmail));
        dispatch(
          modalActions.success(Languages.getValue("userAccountActivisionTxt"))
        );
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(failure(error.toString()));
        dispatch(modalActions.error(error));
      }
    );
  };
  function request() {
    return { type: userConstants.USER_CONFIRM_PROFILE_REQUEST, addUserEmail };
  }
  function success(addUserEmail) {
    return { type: userConstants.USER_CONFIRM_PROFILE_SUCCESS, addUserEmail };
  }
  function failure(error) {
    return { type: userConstants.USER_CONFIRM_PROFILE_FAILURE, error };
  }
}

function forgotPasswordAsyc(addUserEmail) {
  return (dispatch) => {
    dispatch(request());
    userService.forgotPassword(addUserEmail).then(
      () => {
        dispatch(modalActions.success(""));
        dispatch(success(addUserEmail));
        dispatch(
          modalActions.success(Languages.getValue("forgotPasswordSendMsgTxt"))
        );
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(failure(error.toString()));
        dispatch(modalActions.error(error));
      }
    );
  };
  function request() {
    return { type: userConstants.USER_FORGOT_PASSWORD_REQUEST, addUserEmail };
  }
  function success(addUserEmail) {
    return { type: userConstants.USER_FORGOT_PASSWORD_SUCCESS, addUserEmail };
  }
  function failure(error) {
    return { type: userConstants.USER_CONFIRM_PROFILE_FAILURE, error };
  }
}
