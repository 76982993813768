export const videoConstants = {
    GETALL_VIDEO_REQUEST: 'VIDEOS_GETALL_REQUEST',
    GETALL_VIDEO_SUCCESS: 'VIDEOS_GETALL_SUCCESS',
    GETALL_VIDEO_FAILURE: 'VIDEOS_GETALL_FAILURE',

    GET_VIDEO_DETAIL_REQUEST: 'VIDEO_DETAIL_GET_REQUEST',
    GET_VIDEO_DETAIL_SUCCESS: 'VIDEO_DETAIL_GET_SUCCESS',
    GET_VIDEO_DETAIL_FAILURE: 'VIDEO_DETAIL_GET_FAILURE',
  
    VIDEO_UPDATE_REQUEST: 'VIDEO_UPDATE_REQUEST',
    VIDEO_UPDATE_SUCCESS: 'VIDEO_UPDATE_SUCCESS',
    VIDEO_UPDATE_FAILURE: 'VIDEO_UPDATE_FAILURE',

    ACTIVE_PASSIVE_VIDEO_REQUEST: 'VIDEO_ACTIVE_PASSIVE_REQUEST',
    ACTIVE_PASSIVE_VIDEO_SUCCESS: 'VIDEO_ACTIVE_PASSIVE_SUCCESS',
    ACTIVE_PASSIVE_VIDEO_FAILURE: 'VIDEO_ACTIVE_PASSIVE_FAILURE',
  
    
    VIDEO_ADD_REQUEST: 'VIDEO_ADD_REQUEST',
    VIDEO_ADD_SUCCESS: 'VIDEO_ADD_SUCCESS',
    VIDEO_ADD_FAILURE: 'VIDEO_ADD_FAILURE',

  };
  