import { documentConstants } from "../constants";
import { couponService } from "../services";
import { documentService } from "../services";
import { couponConstants } from "../constants";
import { modalActions } from "./";
import Languages from "../libraries/languages";
import Resources from "../libraries/resources";

export const documentActions = {
  getDocumentById,
  createdDocument,
  deleteDocument,
  getDocumentRemainSpace,

  //   deleteCoupon,
  //   changeCouponStatus,
};

function getDocumentById(id) {
  return (dispatch) => {
    dispatch(request());

    documentService.getDocument(id).then(
      (document) => {
        dispatch(success(document));
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(modalActions.error(Languages.getValue(error)));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: documentConstants.GET_DOCUMENT_REQUEST };
  }
  function success(document) {
    return {
      type: documentConstants.GET_DOCUMENT_SUCCESS,
      document,
    };
  }
  function failure(error) {
    return { type: documentConstants.GET_DOCUMENT_FAILURE, error };
  }
}
function getDocumentRemainSpace(companyId) {
  return (dispatch) => {
    dispatch(request());

    documentService.getDocumentRemainSpaceById(companyId).then(
      (document) => {
        dispatch(success(document));
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(modalActions.error(Languages.getValue(error)));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: documentConstants.GET_DOCUMENT_REMAIN_SPACE_REQUEST };
  }
  function success(document) {
    return {
      type: documentConstants.GET_DOCUMENT_REMAIN_SPACE_SUCCESS,
      document,
    };
  }
  function failure(error) {
    return { type: documentConstants.GET_DOCUMENT_REMAIN_SPACE_FAILURE, error };
  }
}

function createdDocument(createdDocument, isAdmin) {
  return (dispatch) => {
    dispatch(request(createdDocument));

    documentService.updateDocument(createdDocument).then(
      (createdDocument) => {
        dispatch(modalActions.success(""));
        dispatch(success(createdDocument));
        dispatch(
          modalActions.success(Languages.getValue("addedActionSuccessMsgTxt"))
        );
        isAdmin !== true
          ? (window.location.href =
              Resources.getValue("appBaseEnvURL") + "/#/document")
          : window.history.back();
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(failure(error.toString()));
        dispatch(modalActions.error(error));
      }
    );
  };

  function request(createdDocument) {
    return {
      type: documentConstants.ADD_DOCUMENT_REQUEST,
      createdDocument,
    };
  }
  function success(createdDocument) {
    return {
      type: documentConstants.ADD_DOCUMENT_SUCCESS,
      createdDocument,
    };
  }
  function failure(error) {
    return { type: documentConstants.ADD_DOCUMENT_FAILURE, error };
  }
}

function updateCoupon(updatedCoupon) {
  return (dispatch) => {
    dispatch(request(updatedCoupon));

    couponService.updateCoupon(updatedCoupon).then(
      (updatedCoupon) => {
        dispatch(modalActions.success(""));
        dispatch(success(updatedCoupon));
        dispatch(
          modalActions.success(Languages.getValue("addedActionSuccessMsgTxt"))
        );
        window.location.href =
          Resources.getValue("appBaseEnvURL") + "/#/coupons";
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(failure(error.toString()));
        dispatch(modalActions.error(error));
      }
    );
  };

  function request(updatedCoupon) {
    return {
      type: couponConstants.UPDATE_COUPON_REQUEST,
      updatedCoupon,
    };
  }
  function success(updatedCoupon) {
    return {
      type: couponConstants.UPDATE_COUPON_SUCCESS,
      updatedCoupon,
    };
  }
  function failure(error) {
    return { type: couponConstants.UPDATE_COUPON_FAILURE, error };
  }
}

function deleteDocument(documentId, companyId) {
  return (dispatch) => {
    dispatch(request());

    documentService.deleteDocument(documentId).then(
      () => {
        dispatch(modalActions.success(""));
        dispatch(success());
        dispatch(
          modalActions.success(Languages.getValue("documentDeletedMsgTxt"))
        );
        dispatch(getDocumentById(companyId));
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(modalActions.error(error));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: documentConstants.DELETE_DOCUMENT_REQUEST };
  }
  function success() {
    return { type: documentConstants.DELETE_DOCUMENT_SUCCESS };
  }
  function failure(error) {
    return { type: documentConstants.DELETE_DOCUMENT_FAILURE, error };
  }
}

function searchUserByName(name) {
  return (dispatch) => {
    dispatch(request());

    couponService.searchUserByName(name).then(
      (name) => {
        dispatch(success(name));
      },
      (error) => {
        // dispatch(modalActions.error(''));
        // dispatch(modalActions.error(Languages.getValue(error)));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: couponConstants.GET_USER_BY_NAME_REQUEST };
  }
  function success(name) {
    return {
      type: couponConstants.GET_USER_BY_NAME_SUCCESS,
      name,
    };
  }
  function failure(error) {
    return { type: couponConstants.GET_USER_BY_NAME_FAILURE, error };
  }
}
