import { authHeader } from "../helpers";
import { userService } from "./index";
import Resources from "../libraries/resources";

const apiBaseURL = Resources.getValue("apiBaseEnvURL");

export const corporationService = {
  getAllCorporations,
  getCorporationById,
  updateCorporation,
  updateCorporationInfos,
  addCorporation,
  makeCorporationActivePassive,
  getAllCorporationApplyList,
  getCorporationApplyById,
  updateCorporationApplyInfos,
  addCorporationSubscription,
  updateCompanySubscriptionModel,
  getCorporationSubscriptionInfos,
  getCompanyAdmins,
  deleteCompanyAdmin,
  addCompanyAdmin,
};

function getAllCorporations() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("getCorporationsApiURL")}`,
    requestOptions
  ).then(handleResponse);
}

function getCorporationById(id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ id }),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("getCorporationByIdApiURL")}`,
    requestOptions
  ).then(handleResponse);
}

function addCorporation(company) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(company),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("addCorporationApiURL")}`,
    requestOptions
  ).then(handleResponse);
}

// super admin kurum guncellerken
function updateCorporation(company) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(company),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("updateCorporationApiURL")}`,
    requestOptions
  ).then(handleResponse);
}


// login olan admin kurumunu guncellerken
function updateCorporationInfos(company) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(company),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("updateCorporationInfosApiURL")}`,
    requestOptions
  ).then(handleResponse);
}

function makeCorporationActivePassive(id, isActive) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue(
      "makeActiveOrPassiveCorporationApiURL"
    )}?companyId=${id}&isActive=${isActive}`,
    requestOptions
  ).then(handleResponse);
}

// get corporation subscription apply list
function getAllCorporationApplyList() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("getCorporationApplyListApiURL")}`,
    requestOptions
  ).then(handleResponse);
}

// get company apply detail
function getCorporationApplyById(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue(
      "getCorporationApplyByIdApiURL"
    )}?id=${parseInt(id)}`,
    requestOptions
  ).then(handleResponse);
}

// company apply update
function updateCorporationApplyInfos(apply) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(apply),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("updateCorporationApplyInfosApiURL")}`,
    requestOptions
  ).then(handleResponse);
}

// start company subscription
function addCorporationSubscription(companySubscription) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(companySubscription),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("createCompanySubscriptionApiURL")}`,
    requestOptions
  ).then(handleResponse);
}

//update company subscription detail for kurum admin
function updateCompanySubscriptionModel(companySubscription) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(companySubscription),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue(
      "updateCompanySubscriptionModelApiURL"
    )}`,
    requestOptions
  ).then(handleResponse);
}

// get company subscription detail for kurum admin
function getCorporationSubscriptionInfos() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("getCompanySubscriptionDetailsApiURL")}`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
      }

      const error =
        (data && data.message) ||
        (data && data.errors && data.errors[0]) ||
        response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}


function addCompanyAdmin(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify( data ),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("addCompanyAdminApiURL")}`,
    requestOptions
  ).then(handleResponse);
}


function deleteCompanyAdmin(userId) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue(
      "deleteCompanyAdminApiURL"
    )}?userId=${parseInt(userId)}`,
    requestOptions
  ).then(handleResponse);
}

function getCompanyAdmins(companyId) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue(
      "getCompanyAdminsApiURL"
    )}?companyId=${parseInt(companyId)}`,
    requestOptions
  ).then(handleResponse);
}
