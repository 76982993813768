import { number } from "prop-types";
import { couponConstants } from "../constants";

const initialState = {
  items: [],
  createCoupon: {},
  updateCoupon: {},
  usersByName: [],
  coupon: [],
};

export function coupon(state = initialState, action) {
  switch (action.type) {
    case couponConstants.GET_ALL_COUPON_REQUEST:
      return {
        ...state,
        loading: true,
        items: [],
      };
    case couponConstants.GET_ALL_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.coupons,
        error: "",
      };

    case couponConstants.GET_ALL_COUPON_FAILURE:
      return {
        ...state,
        items: [],
        loading: false,
        error: action.coupons,
      };
    case couponConstants.GET_COUPON_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        coupon: [],
      };
    case couponConstants.GET_COUPON_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        coupon: action.coupon,
        error: "",
      };
    case couponConstants.GET_COUPON_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.coupon,
      };
    case couponConstants.GET_USER_BY_NAME_REQUEST:
      return {
        ...state,
        loading: true,
        usersByName: [],
      };
    case couponConstants.GET_USER_BY_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        usersByName: action.name,
        error: "",
      };

    case couponConstants.GET_USER_BY_NAME_FAILURE:
      return {
        ...state,
        usersByName: [],
        loading: false,
        error: action.name,
      };

    case couponConstants.ADD_COUPON_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case couponConstants.ADD_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case couponConstants.ADD_COUPON_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.createCoupon,
      };
    case couponConstants.UPDATE_COUPON_REQUEST:
      return {
        ...state,
        updateCoupon: {},
        loading: true,
      };
    case couponConstants.UPDATE_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        updateCoupon: action.updateCoupon,
        error: "",
      };
    case couponConstants.UPDATE_COUPON_FAILURE:
      return {
        ...state,
        loading: false,
        updateCoupon: {},
        error: action.updateCoupon,
      };
    case couponConstants.CHANGE_COUPON_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case couponConstants.CHANGE_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case couponConstants.CHANGE_COUPON_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.changeCouponStatus,
      };
    case couponConstants.DELETE_COUPON_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case couponConstants.DELETE_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case couponConstants.DELETE_COUPON_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.deleteCoupon,
      };
    default:
      return state;
  }
}
