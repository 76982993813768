import { sidebarConstants } from '../constants';

var windowWidth = window.innerWidth
|| document.documentElement.clientWidth
|| document.body.clientWidth;

var initialState;

if(windowWidth < 992) {
  initialState = {
    sidebarShow: false
  }
} else {
  initialState = {
    sidebarShow: true
  }
}

export function sidebar (state = initialState, action) {
  switch (action.type) {
    case sidebarConstants.SIDEBAR_SET:
      return {
        sidebarShow: !state.sidebarShow
      }
    default:
      return state
  }
}
