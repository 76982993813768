import React, { Component, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import {
  TheContent,
  TheSidebar,
  TheFooter,
  TheHeader
} from './index';

const TheLayout = () => {
  const history = useHistory();

  useEffect(() => {
    // token expired control
    const token = localStorage.getItem('adminToken');
    if (token) {
      const tokenJSON = JSON.parse(atob(token.split('.')[1]));
      if (tokenJSON.exp < new Date().getTime() / 1000) { // ms cinsinden
        console.log("EXPIRED");
        history.push('/login');
        localStorage.removeItem('adminUserInfo');
        localStorage.removeItem('adminRefreshToken');
        localStorage.removeItem('adminToken');
        localStorage.removeItem("selectedCorporation");
      }
    }
  });

  return (
    <div className="c-app c-default-layout">
      <TheSidebar />
      <div className="c-wrapper">
        <TheHeader />
        <div className="c-body">
          <TheContent />
        </div>
        <TheFooter />
      </div>
    </div>
  )
}

export default TheLayout;
