import { modalConstants } from '../constants';

export function modal(state = {}, action) {
  switch (action.type) {
    case modalConstants.SUCCESS:
      return {
        type: 'success',
        message: action.message
      };
    case modalConstants.ERROR:
      return {
        type: 'danger',
        message: action.message
      };
    case modalConstants.INFO:
      return {
        type: 'info',
        message: action.message
      };
    case modalConstants.WARNING:
      return {
        type: 'warning',
        message: action.message
      };
    case modalConstants.CLEAR:
      return {};
    default:
      return state
  }
}
