export const documentConstants = {
  GET_DOCUMENT_REQUEST: "GET_DOCUMENT_REQUEST",
  GET_DOCUMENT_SUCCESS: "GET_DOCUMENT_SUCCESS",
  GET_DOCUMENT_FAILURE: "GET_DOCUMENT_FAILURE",

  ADD_DOCUMENT_REQUEST: "ADD_DOCUMENT_REQUETS",
  ADD_DOCUMENT_SUCCESS: "ADD_DOCUMENT_SUCCESS",
  ADD_DOCUMENT_FAILURE: "ADD_DOCUMENT_FAILURE",

  UPDATE_DOCUMENT_REQUEST: "UPDATE_DOCUMENT_REQUEST",
  UPDATE_DOCUMENT_SUCCESS: "UPDATE_DOCUMENT_SUCCESS",
  UPDATE_DOCUMENT_FAILURE: "UPDATE_DOCUMENT_FAILURE",

  DELETE_DOCUMENT_REQUEST: "DELETE_DOCUMENT_REQUEST",
  DELETE_DOCUMENT_SUCCESS: "DELETE_DOCUMENT_SUCCESS",
  DELETE_DOCUMENT_FAILURE: "DELETE_DOCUMENT_FAILURE",

  GET_DOCUMENT_REMAIN_SPACE_REQUEST: "GET_DOCUMENT_REMAIN_SPACE_REQUEST",
  GET_DOCUMENT_REMAIN_SPACE_SUCCESS: "GET_DOCUMENT_REMAIN_SPACE_SUCCESS",
  GET_DOCUMENT_REMAIN_SPACE_FAILURE: "GET_DOCUMENT_REMAIN_SPACE_FAILURE",
};
