import { authHeader } from "../helpers";
import { userService } from "./index";
import Resources from "../libraries/resources";

const apiBaseURL = Resources.getValue("apiBaseEnvURL");

export const feeService = {
  getFee,
  updateFee,
  getFeeById,
};

function updateFee(fee) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(fee),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("updateFeesApiURL")}`,
    requestOptions
  ).then(handleResponse);
}

function getFee() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("getFeesApiURL")}`,
    requestOptions
  ).then(handleResponse);
}

function getFeeById(productId) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("getFeesByIdApiUrl")}/${productId}`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
      }

      const error =
        (data && data.message) ||
        (data && data.errors && data.errors[0]) ||
        response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
