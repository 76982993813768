export const feedbackConstants = {
  GET_FEEDBACKS_REQUEST: 'FEEDBACKS_GET_REQUEST',
  GET_FEEDBACKS_SUCCESS: 'FEEDBACKS_GET_SUCCESS',
  GET_FEEDBACKS_FAILURE: 'FEEDBACKS_GET_FAILURE',

  FEEDBACK_APPROVE_REQUEST: 'FEEDBACK_APPROVE_REQUEST',
  FEEDBACK_APPROVE_SUCCESS: 'FEEDBACK_APPROVE_SUCCESS',
  FEEDBACK_APPROVE_FAILURE: 'FEEDBACK_APPROVE_FAILURE',

  FEEDBACK_REJECT_REQUEST: 'FEEDBACK_REJECT_REQUEST',
  FEEDBACK_REJECT_SUCCESS: 'FEEDBACK_REJECT_SUCCESS',
  FEEDBACK_REJECT_FAILURE: 'FEEDBACK_REJECT_FAILURE',
};
