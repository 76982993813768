import Languages from "../libraries/languages";

// superadmin
export const superadminMenus = [
  {
    _tag: "CSidebarNavItem",
    name: Languages.getValue("homepageMsgTxt"),
    to: "/dashboard",
    icon: "cil-home",
  },
  {
    _tag: "CSidebarNavTitle",
    _children: [Languages.getValue("menusMsgTxt")],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: Languages.getValue("userActionsMsgTxt"),
    route: "/base",
    icon: "cil-user",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: Languages.getValue("userListMsgTxt"),
        to: "/users",
      },
      {
        _tag: "CSidebarNavItem",
        name: Languages.getValue("userAddMsgTxt"),
        to: "/user/add",
      },
      {
        _tag: "CSidebarNavItem",
        name: Languages.getValue("userTimeExtensionmsgTxt"),
        to: "/user/time",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: Languages.getValue("corporationActionsMsgTxt"),
    route: "/base",
    icon: "cil-home",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: Languages.getValue("corporationListMsgTxt"),
        to: "/corporations",
      },
      {
        _tag: "CSidebarNavItem",
        name: Languages.getValue("corporationAddMsgTxt"),
        to: "/corporations/add",
      },
    ],
  },

  {
    _tag: "CSidebarNavDropdown",
    name: Languages.getValue("feedbackActionsMsgTxt"),
    route: "/base",
    icon: "cil-comment-square",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: Languages.getValue("feedbackListMsgTxt"),
        to: "/feedbacks",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: Languages.getValue("blogActionsMsgTxt"),
    route: "/base",
    icon: "cil-pencil",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: Languages.getValue("blogListMsgTxt"),
        to: "/blogs",
      },
      {
        _tag: "CSidebarNavItem",
        name: Languages.getValue("blogAddMsgTxt"),
        to: "/blogs/add",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: Languages.getValue("chatGptActions"),
    route: "/base",
    icon: "cil-user",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: Languages.getValue("addChatGptPackage"),
        to: "/packages",
      },
      {
        _tag: "CSidebarNavItem",
        name: Languages.getValue("assignChatGptPackage"),
        to: "/package/assign",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: Languages.getValue("videoActionsMsgTxt"),
    route: "/base",
    icon: "cil-file",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: Languages.getValue("videoListMsgTxt"),
        to: "/videos",
      },
      {
        _tag: "CSidebarNavItem",
        name: Languages.getValue("newVideoAddMsgTxt"),
        to: "/video/add",
      },
    ],
  },

  {
    _tag: "CSidebarNavDropdown",
    name: Languages.getValue("feeActionsMsgTxt"),
    route: "/base",
    icon: "cil-basket",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: Languages.getValue("feeListMsgTxt"),
        to: "/fee",
      },
      {
        _tag: "CSidebarNavItem",
        name: Languages.getValue("couponListMsgTxt"),
        to: "/coupons",
      },
      {
        _tag: "CSidebarNavItem",
        name: Languages.getValue("discountCouponMsgtxt"),
        to: "/coupon",
      },
    ],
  },

  {
    _tag: "CSidebarNavItem",
    name: Languages.getValue("adminProfileMsgTxt"),
    to: "/profile",
    icon: "cil-settings",
  },

  {
    _tag: "CSidebarNavDivider",
    className: "m-2",
  },
];

// kurum admin
export const adminMenus = [
  {
    _tag: "CSidebarNavItem",
    name: Languages.getValue("homepageMsgTxt"),
    to: "/dashboard",
    icon: "cil-home",
  },
  {
    _tag: "CSidebarNavTitle",
    _children: [Languages.getValue("menusMsgTxt")],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: Languages.getValue("userActionsMsgTxt"),
    route: "/base",
    icon: "cil-user",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: Languages.getValue("userListMsgTxt"),
        to: "/users",
      },
      {
        _tag: "CSidebarNavItem",
        name: Languages.getValue("userAddMsgTxt"),
        to: "/user/add",
      },
    ],
  },
  {
    _tag: "CSidebarNavItem",
    name: Languages.getValue("documentOperationMsgTxt"),
    to: "/document",
    icon: "cil-file",
  },
  {
    _tag: "CSidebarNavItem",
    name: Languages.getValue("subscriptionDetailsMsgTxt"),
    to: "/subscription-details",
    icon: "cil-star",
  },
  {
    _tag: "CSidebarNavItem",
    name: Languages.getValue("adminProfileMsgTxt"),
    to: "/profile",
    icon: "cil-settings",
  },
  {
    _tag: "CSidebarNavDivider",
    className: "m-2",
  },
];
