import { feedbackConstants } from '../constants';
import { feedbackService } from '../services';
import { modalActions } from './';
import Languages from '../libraries/languages';

export const feedbackActions = {
  getAllFeedbacks,
  approveFeedback,
  rejectFeedback
};

function getAllFeedbacks() {
  return dispatch => {
    dispatch(request());

    feedbackService.getAllFeedbacks()
      .then(
        feedbacks => {
          dispatch(success(feedbacks));
        },
        error => {
          dispatch(modalActions.error(''));
          dispatch(modalActions.error(Languages.getValue(error)));
          dispatch(failure(error.toString()))
        }
      );
  };

  function request() { return { type: feedbackConstants.GET_FEEDBACKS_REQUEST } }
  function success(feedbacks) { return { type: feedbackConstants.GET_FEEDBACKS_SUCCESS, feedbacks } }
  function failure(error) { return { type: feedbackConstants.GET_FEEDBACKS_FAILURE, error } }
}

function approveFeedback(data) {
  return dispatch => {
    dispatch(request());

    feedbackService.approveFeedback(data)
      .then(
        data => {
          dispatch(modalActions.success(''));
          dispatch(success(data));
          dispatch(modalActions.success(Languages.getValue('approveActionSuccessMsgTxt')));
          feedbackActions.getAllFeedbacks();
        },
        error => {
          dispatch(modalActions.error(''));
          dispatch(failure(error.toString()));
          dispatch(modalActions.error(error));
        }
      );
  };

  function request() { return { type: feedbackConstants.FEEDBACK_APPROVE_REQUEST } }
  function success(approveFeedback) { return { type: feedbackConstants.FEEDBACK_APPROVE_SUCCESS, approveFeedback } }
  function failure(error) { return { type: feedbackConstants.FEEDBACK_APPROVE_FAILURE, error } }
}

function rejectFeedback(data) {
  return dispatch => {
    dispatch(request());

    feedbackService.rejectFeedback(data)
      .then(
        data => {
          dispatch(modalActions.success(''));
          dispatch(success(data));
          dispatch(modalActions.success(Languages.getValue('rejectActionSuccessMsgTxt')));
          feedbackActions.getAllFeedbacks();
        },
        error => {
          dispatch(modalActions.error(''));
          dispatch(failure(error.toString()));
          dispatch(modalActions.error(error));
        }
      );
  };

  function request() { return { type: feedbackConstants.FEEDBACK_REJECT_REQUEST } }
  function success(rejectFeedback) { return { type: feedbackConstants.FEEDBACK_REJECT_SUCCESS, rejectFeedback } }
  function failure(error) { return { type: feedbackConstants.FEEDBACK_REJECT_FAILURE, error } }
}
