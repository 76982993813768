import { userActions } from "../actions";
import { userConstants } from "../constants";

const initialState = {
  items: [],
  detailedUser: {},
  userTypes: [],
  error: "",
};

export function users(state = initialState, action) {
  switch (action.type) {
    case userConstants.GETALL_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        detailedUser: {},
      };
    case userConstants.GETALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        detailedUser: {},
        items: action.users,
      };
    case userConstants.GETALL_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        items: [],
        detailedUser: {},
        error: action.error,
      };
    case userConstants.ADD_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case userConstants.ADD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case userConstants.ADD_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.ADD_USER_TO_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case userConstants.ADD_USER_TO_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case userConstants.ADD_USER_TO_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.UPDATE_USER_TO_TRIAL_DAY_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case userConstants.UPDATE_USER_TO_TRIAL_DAY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case userConstants.UPDATE_USER_TO_TRIAL_DAY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
        detailedUser: {},
      };
    case userConstants.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        detailedUser: action.detailedUser,
        error: "",
      };
    case userConstants.GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        detailedUser: {},
      };
    case userConstants.GET_ALL_USER_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.GET_ALL_USER_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        userTypes: action.types,
      };
    case userConstants.GET_ALL_USER_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        userTypes: [],
        error: action.error,
      };
    case userConstants.ACTIVE_PASSIVE_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case userConstants.ACTIVE_PASSIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case userConstants.ACTIVE_PASSIVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.USER_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case userConstants.USER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case userConstants.USER_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.USER_CONFIRM_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case userConstants.USER_CONFIRM_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case userConstants.USER_CONFIRM_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.USER_FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: action.error,
      };
    case userConstants.USER_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case userConstants.USER_CONFIRM_PROFILE_FAILURE:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
}
