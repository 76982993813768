import { feeConstants } from "../constants";

const initialState = {
  items: [],
  detailedFee: {},
  error: "",
  deletedFee: {},
};

export function fee(state = initialState, action) {
  switch (action.type) {
    case feeConstants.GETALL_FEE_REQUEST:
      return {
        ...state,
        loading: true,
        detailedFee: {},
      };
    case feeConstants.GETALL_FEE_SUCCESS:
      return {
        ...state,
        loading: false,
        detailedFee: {},
        error: "",
        items: action.fee,
      };
    case feeConstants.GETALL_FEE_FAILURE:
      return {
        ...state,
        loading: false,
        items: [],
        detailedFee: {},
        error: action.error,
      };
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case feeConstants.GET_FEE_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        detailedFee: {},
        error: "",
      };
    case feeConstants.GET_FEE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        detailedFee: action.detailedFee,
        error: "",
      };
    case feeConstants.GET_FEE_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        detailedFee: {},
      };
    case feeConstants.FEE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case feeConstants.FEE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        detailedFee: action.updatedFee.data,
      };
    case feeConstants.FEE_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
}
