import { authHeader } from "../helpers";
import Resources from "../libraries/resources";

const apiBaseURL = Resources.getValue("apiBaseEnvURL");
const loggedInUser = JSON.parse(localStorage.getItem("adminUserInfo"));
const selectedCorporation = JSON.parse(
  localStorage.getItem("selectedCorporation")
);

export const userService = {
  login,
  logout,
  getAllUsers,
  getAllUserTypes,
  makeUserActivePassive,
  getUserById,
  updateUser,
  userAdd,
  addUserToCorporation,
  updateUserTimeExtension,
  confirmUserAsync,
  forgotPassword,
};

function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("accountLoginApiURL")}`,
    requestOptions
  )
    .then(handleResponse)
    .then((user) => {
      localStorage.setItem("adminUserInfo", JSON.stringify(user));
      localStorage.setItem(
        "adminRefreshToken",
        JSON.stringify(user.refreshToken)
      );
      localStorage.setItem("adminToken", JSON.stringify(user.token));
      window.location.href = Resources.getValue("appBaseEnvURL") + "/dashboard";
      return user;
    });
}

function logout() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("accountLogoutApiURL")}`,
    requestOptions
  )
    .then(handleResponse)
    .then(() => {
      localStorage.removeItem("adminUserInfo");
      localStorage.removeItem("adminRefreshToken");
      localStorage.removeItem("adminToken");
      localStorage.removeItem("selectedCorporation");
    });
}

function getAllUsers() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("getUsersApiURL")}?companyId=${
      selectedCorporation ? selectedCorporation.id : loggedInUser.companyId
    }`,
    requestOptions
  ).then(handleResponse);
}

function getAllUserTypes() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("getAllUserTypesApiURL")}`,
    requestOptions
  ).then(handleResponse);
}

function makeUserActivePassive(userId, isActive) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue(
      "makeActiveOrPassiveUserApiURL"
    )}?userId=${userId}&isActive=${isActive}`,
    requestOptions
  ).then(handleResponse);
}

function getUserById(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("getUserByIdApiURL")}?userId=${id}`,
    requestOptions
  ).then(handleResponse);
}

function updateUser(user) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("updateUserApiURL")}`,
    requestOptions
  ).then(handleResponse);
}

function userAdd(user) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("addUserApiURL")}`,
    requestOptions
  ).then(handleResponse);
}
function updateUserTimeExtension(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("updateUserTimeExtensionApiURL")}`,
    requestOptions
  ).then(handleResponse);
}

function addUserToCorporation(user) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("addUserToCorporationApiURL")}`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 respx"onse returned from api
        if (localStorage.getItem("adminUserInfo")) {
          logout();
        }
      }

      const error =
        (data && data.message) ||
        (data && data.errors && data.errors[0]) ||
        response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function confirmUserAsync(email) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    // body: JSON.stringify(email),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue(
      "accountConfirmUserURL"
    )}?Email=${email}`,
    // `${apiBaseURL}${Resources.getValue("accountConfirmUserURL")}`,
    requestOptions
  ).then(handleResponse);
}

function forgotPassword(email) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(email),
  };
  return fetch(
    `${apiBaseURL}${Resources.getValue("forgotPasswordUrl")}`,
    requestOptions
  ).then(handleResponse);
}
