import { profileConstants } from '../constants';
import { profileService } from '../services';
import { modalActions } from './';
import Languages from '../libraries/languages';

export const profileActions = {
  getProfile,
  updateProfile,
  changePassword,
};

function getProfile(id) {
  return dispatch => {
    dispatch(request());

    profileService.getProfile(id)
      .then(
        datas => {
          dispatch(success(datas.user));
        },
        error => {
          dispatch(modalActions.error(''));
          dispatch(modalActions.error(Languages.getValue(error)));
          dispatch(failure(error.toString()))
        }
      );
  };

  function request() { return { type: profileConstants.GET_PROFILE_REQUEST } }
  function success(profileInfos) { return { type: profileConstants.GET_PROFILE_SUCCESS, profileInfos } }
  function failure(error) { return { type: profileConstants.GET_PROFILE_FAILURE, error } }
}

function updateProfile(data) {
  return dispatch => {
    dispatch(request());

    profileService.updateProfile(data)
      .then(
        data => {
          dispatch(modalActions.success(''));
          dispatch(success(data));
          dispatch(modalActions.success(Languages.getValue('updateActionSuccessMsgTxt')));
        },
        error => {
          dispatch(modalActions.error(''));
          dispatch(modalActions.error(Languages.getValue('errorOccuredMsgTxt')));
          dispatch(failure(error.toString()));
        }
      );
  };

  function request() { return { type: profileConstants.PROFILE_UPDATE_REQUEST } }
  function success(updatedProfile) { return { type: profileConstants.PROFILE_UPDATE_SUCCESS, updatedProfile } }
  function failure(error) { return { type: profileConstants.PROFILE_UPDATE_FAILURE, error } }
}

function changePassword(data) {
  return dispatch => {
    dispatch(request());

    profileService.changePassword(data)
      .then(
        data => {
          dispatch(modalActions.success(''));
          dispatch(success(data));
          dispatch(modalActions.success(Languages.getValue('updateActionSuccessMsgTxt')));
        },
        error => {
          dispatch(modalActions.error(''));
          dispatch(modalActions.error(Languages.getValue('errorOccuredMsgTxt')));
          dispatch(failure(error.toString()));
        }
      );
  };

  function request() { return { type: profileConstants.CHANGE_PASSWORD_REQUEST } }
  function success(updatedPAssword) { return { type: profileConstants.CHANGE_PASSWORD_SUCCESS, updatedPAssword } }
  function failure(error) { return { type: profileConstants.CHANGE_PASSWORD_FAILURE, error } }
}
