import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from '@coreui/react';

// sidebar nav config
import { superadminMenus, adminMenus } from './_nav';

import Languages from '../libraries/languages';

// logos
import LogoBig from '../assets/img/foramind_logo_big__renkli.png';
import LogoSmall from '../assets/img/foramind_logo_small__renkli.png';

// Actions
import { corporationActions } from '../actions';

const TheSidebar = () => {
  const dispatch = useDispatch();

  // Get logged in admin info
  const loggedInUser = useSelector(state => state.authentication.user);
  const adminRole = loggedInUser && loggedInUser.roleIdList[0];

  const show = useSelector(state => state.sidebar.sidebarShow);
  const allCorporations = useSelector(state => state.corporations.items);
  const selectedCorporation = useSelector(state => state.corporations.selectedCorporation);
  const corporationDetail = useSelector(state => state.corporations.detailedCorporation);

  useEffect(() => {
    (adminRole === 3) && dispatch(corporationActions.getAllCorporations());
    (adminRole === 1) && dispatch(corporationActions.getCorporationDetailById(loggedInUser.companyId));
  }, [dispatch]);

  const selectCorporation = (e) => {
    const index = e.nativeEvent.target.selectedIndex;
    const selectedVal = e.nativeEvent.target[index].value;
    dispatch(corporationActions.getCorporationById(selectedVal));
  }

  return (
    <CSidebar
      show={show}
    >
      <CSidebarBrand className="d-md-down-none px-3 py-2" to="/">
        {/* super admin icin listeden kurum secildiyse ve kurumun logosu varsa, secilen kurum ile update edilen kurum ayni ise */}
        {(adminRole === 3 && selectedCorporation && selectedCorporation.logo && (corporationDetail.id === selectedCorporation.id)) && (
          <>
            <img
              src={corporationDetail.logo}
              alt={corporationDetail.companyName}
              className="c-sidebar-brand-full"
              height="auto" width="100%"
            />
            <img src={LogoSmall} alt="Foramind" className="c-sidebar-brand-minimized" height={40} />
          </>
        )}
        {/* super admin icin listeden kurum secildiyse ve secilen kurum ile update edilen kurum ayni degil ise */}
        {(adminRole === 3 && selectedCorporation && selectedCorporation.logo && (corporationDetail.id !== selectedCorporation.id)) && (
          <>
            <img
              src={selectedCorporation.logo}
              alt={selectedCorporation.companyName}
              className="c-sidebar-brand-full"
              height="auto" width="100%"
            />
            <img src={LogoSmall} alt="Foramind" className="c-sidebar-brand-minimized" height={40} />
          </>
        )}
        {/* super admin icin listeden kurum secildiyse ve kurumun logosu yoksa */}
        {(adminRole === 3 && !selectedCorporation.logo) && (
            <>
              <img
                src={LogoBig}
                alt="Foramind"
                className="c-sidebar-brand-full"
                height="auto" width="100%"
              />
              <img src={LogoSmall} alt="Foramind" className="c-sidebar-brand-minimized" height={40} />
            </>
          )}
        {/* kurum admin icin kayitli logo bilgisi varsa */}
        {(adminRole === 1 && corporationDetail && corporationDetail.logo) && (
          <>
            <img
              src={corporationDetail.logo}
              alt={corporationDetail.companyName}
              className="c-sidebar-brand-full"
              height="auto" width="100%"
            />
            <img src={LogoSmall} alt="Foramind" className="c-sidebar-brand-minimized" height={40} />
          </>
        )}
        {/* kurum admin icin kayitli logo bilgisi yoksa */}
        {(adminRole === 1 && !corporationDetail.logo) && (
          <>
            <img
              src={LogoBig}
              alt="Foramind"
              className="c-sidebar-brand-full"
              height="auto" width="100%"
            />
            <img src={LogoSmall} alt="Foramind" className="c-sidebar-brand-minimized" height={40} />
          </>
        )}
      </CSidebarBrand>
      {/* Corporation Select */}
      {(adminRole === 3) && (
        <div className="w-100 mx-auto my-3 px-3">
          {/* defaultValue={(selectedCorporation) ? `${selectedCorporation.id}` : '-1'} */}
          {/* selected={(selectedCorporation && (selectedCorporation.id === item.id)) ? true : false} */}
          <select
            name="corporationSelect"
            id="corporationSelect"
            className="corporation-select form-control"
            onChange={(e) => selectCorporation(e)}
            value={(selectedCorporation) ? `${selectedCorporation.id}` : '-1'}
          >
            <option value="-1">
              {Languages.getValue('selectCorporationMsgTxt') + ' (' + Languages.getValue('defaultMsgTxt') + ': Kafein)'}
            </option>
            {(allCorporations?.length) && allCorporations?.map(item => (
              <option
                key={item.id}
                value={item.id}
              >
                {item.companyName}
              </option>
            ))}
          </select>
        </div>
      )}
      {/* Corporation Select End */}
      <CSidebarNav>
        <CCreateElement
          items={(adminRole === 3) ? superadminMenus : adminMenus}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  )
}

export default React.memo(TheSidebar)
