import React, { Component } from "react";
import Select from "react-select";
import Languages from "../libraries/languages";

class TheHeaderDropdownLanguage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      languageSelectVal: {
        label: Languages.siteLanguage.toUpperCase(),
        value: Languages.siteLanguage
      }
    };
  }

  checkSelectedLanguage() {
    var lang = Languages.siteLanguage;
    if (lang) {
      this.setState({ languageSelectVal: { label: lang.toUpperCase(), value: lang } });
    }
  };

  componentDidMount() {
    this.checkSelectedLanguage();
  };

  languageSelectOnChange(selectedItem) {
    localStorage.setItem('siteLanguage', selectedItem.value);
    window.location.reload();
  };

  render() {
    var languageOptions = [
      { label: 'TR', value: 'tr' },
      { label: 'EN', value: 'en' }
    ];
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color: 'black',
        padding: 0,
      }),

      menu: (provided, state) => ({
        ...provided,
        color: 'white',
        padding: 2,
        width: 60,
      }),

      control: styles => ({
        ...styles,
        backgroundColor: 'white',
        width: 60,
        height: 25,
        fontSize: 10,
        marginLeft: -1
      }),

      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        return { ...provided, opacity, transition };
      }
    }

    return (
      <Select
        id="shape"
        ref={input => this.languageSelect = input}
        styles={customStyles}
        className="fa-select language-select select"
        options={languageOptions}
        onChange={selectedItem => this.languageSelectOnChange(selectedItem)}
        value={this.state.languageSelectVal}
      />
    );
  }
}

export default TheHeaderDropdownLanguage;
