import React, { useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CRow,
  CInput,
  CInputGroupPrepend,
  CInputGroupText,
  CInputGroup,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import Modal from "../../../components/modal";
import LanguageDropdown from "../../../containers/TheHeaderDropdownLanguage";
import { userActions } from "../../../actions";
import { useSelector, useDispatch } from "react-redux";

import Languages from "../../../libraries/languages";
import { Formik } from "formik";
import * as Yup from "yup";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [mail, setMail] = useState({
    email: "",
  });
  const [errorMsg, setErrorMsg] = useState("");

  const submitForm = (data) => {
    dispatch(userActions.forgotPasswordAsyc(data));
  };

  return (
    <div className="c-app c-default-layout login-page flex-row align-items-center">
      <LanguageDropdown />
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <Modal type="warning" message={errorMsg} />
                  <Formik
                    initialValues={mail}
                    onSubmit={(values, { setSubmitting }) => {
                      submitForm(values);
                      setSubmitting(false);
                    }}
                    validationSchema={Yup.object().shape({
                      email: Yup.string()
                        .email()
                        .required(Languages.getValue("requiredMsgTxt")),
                    })}
                  >
                    {(props) => {
                      const {
                        values,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleSubmit,
                      } = props;
                      return (
                        <form onSubmit={handleSubmit}>
                          <h1>{Languages.getValue("forgotPasswordMsgTxt")}</h1>
                          <p className="text-muted">
                            {Languages.getValue("forgotPasswordInfoMsgTxt")}
                          </p>
                          <CInputGroup className="mb-3">
                            <CInputGroupPrepend>
                              <CInputGroupText>
                                <CIcon name="cil-envelope-closed" />
                              </CInputGroupText>
                            </CInputGroupPrepend>
                            <CInput
                              type="email"
                              name="email"
                              placeholder={Languages.getValue("emailMsgTxt")}
                              value={values.email}
                              onChange={handleChange}
                              className={errors.email && "error"}
                            />
                            {errors.email && (
                              <div className="input-feedback w-100">
                                {errors.email}
                              </div>
                            )}
                          </CInputGroup>
                          <CRow>
                            <CCol xs="6">
                              <CButton
                                type="submit"
                                color="primary"
                                className="px-4"
                                disabled={isSubmitting}
                              >
                                {Languages.getValue("sendMsgTxt")}
                              </CButton>
                            </CCol>
                          </CRow>
                        </form>
                      );
                    }}
                  </Formik>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ForgotPassword;
