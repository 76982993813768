var siteLanguage = (function () {
  var defaultLanguage = (function () {
    return "en";
  })();

  var activeLanguage = (function () {
    var storageData = localStorage.getItem("siteLanguage");
    if (storageData) {
      return storageData;
    } else {
      localStorage.setItem("siteLanguage", defaultLanguage);
      return defaultLanguage;
    }
  })();

  return activeLanguage;
})();

var Languages = {
  siteLanguage: siteLanguage,

  getValue: function (key) {
    if (typeof Languages[siteLanguage][key] != "undefined")
      return Languages[siteLanguage][key];
  },

  tr: {
    homepageMsgTxt: "Anasayfa",
    homeMsgTxt: "Anasayfa",
    copyUrlMsgTxt: "URL'i Kopyala",
    copyIDMsgTxt: "ID'yi Kopyala",
    userMsgTxt: "Kullanıcı",
    makeUserActiveProfileTxt: "Kullanıcıyı Aktif Et",
    userAccountActivisionTxt: "Kullanıcı Aktif Edildi",
    usersMsgTxt: "Kullanıcılar",
    updateUserMsgTxt: "Kullanıcı Güncelle",
    settingsMsgTxt: "Ayarlar",
    loginMsgTxt: "Giriş",
    forgotPasswordSendMsgTxt:
      "Şifre sıfırlama ile ilgili bilgiler e-posta adresinize gönderilmiştir.",
    signInMsgTxt: "Hesabınıza giriş yapın",
    usernameMsgTxt: "Kullanıcı Adı",
    enterUserNameMsgTxt: "Kullanıcı Adı Giriniz",
    passwordMsgTxt: "Şifre",
    forgotPasswordMsgTxt: "Şifremi Unuttum",
    menusMsgTxt: "Menüler",
    makeYourChoiceMsgTxt: "Seçim Yapın",
    chatGptActions: "Yapay Zeka İşlemleri",
    addChatGptPackage: "Yapay Zeka Paket Ekleme",
    corporationDocumentMsgTxt: "Kurum Dökümanları",
    documentSizeLimitMsgTxt: "Döküman Saklama Alanı",
    chatGptPackages: "Yapay Zeka Paketleri",
    packageNameMsgTxt: "Paket Adı",
    forgotPasswordSendMsgTxt:
      "Şifre sıfırlama ile ilgili bilgiler e-posta adresinize gönderilmiştir.",
    packageAddMsgTxt: "Paket Ekle",
    numberOfMaps: "Map hakkı",
    documentName: "Dökuman Adı",
    documentsMsgTxt: "Dökümanlar",
    fileMsgTxt: "Döküman",
    fileUploadMsgTxt: "Döküman Yüke",
    selectFileTypeMsgTxt: "Dosya Tipi Seçiniz",
    documentSize: "Döküman Boyutu",
    documentOperationMsgTxt: "Döküman İşlemleri",
    documentAdd: "Döküman Ekle",
    fileName: "Dosya Adı",
    fileType: "Dosya Tipi",
    contentName: "İçerik Adı",
    assignChatGptPackage: "Yapay Zeka Paket Tanımlama",
    packageAssignCompanyUserMsgTxt: "Kurumsal - Yapay Zeka Paket Tanımlama",
    packageAssignUserMsgTxt: "Bireysel - Yapay Zeka Paket Tanımlama",
    userActionsMsgTxt: "Kullanıcı İşlemleri",
    changeCouponMsgTxt: "Kuponun Statusu Başarılı Şekilde Değiştirildi ",
    discountCodeMsgTxt: "İndirim Kodu",
    userIdMsgTxt: "Kullanıcı Id'si",
    userListMsgTxt: "Kullanıcı Listesi",
    userAddMsgTxt: "Kullanıcı Ekle",
    userTimeExtensionmsgTxt: "Deneme Süresi Güncelleme",
    userDetailMsgTxt: "Kullanıcı Detay",
    currentUserCountInfoMsgTxt: "Mevcut Kullanıcı Sayısı",
    currentConditionMsgTxt: "Mevcut Durum",
    commentsMsgTxt: "Yorumlar",
    commentMsgTxt: "Yorum",
    createCouponMsgTxt: "Kupon Oluştur",
    addCouponMsgTxt: "Kupon Ekle",
    couponUpdateMsgTxt: "Kupon Güncelleme",
    commentDetailMsgTxt: "Yorum Detay",
    documentOperationMsgTxt: "Döküman İşlemleri",
    commentActionsMsgTxt: "Yorum İşlemleri",
    commentListMsgTxt: "Yorum Listesi",
    feedbacksMsgTxt: "Geri Bildirimler",
    feedbackMsgTxt: "Geri Bildirim",
    feedbackListMsgTxt: "Geri Bildirim Listesi",
    feedbackDetailMsgTxt: "Geri Bildirim Detay",
    documentDeletedMsgTxt: "Döküman Silindi",
    feedbackActionsMsgTxt: "Geri Bildirim İşlemleri",
    newVideoTitleMsgTxt: "Video Başlığı",
    productOrginalFeeMsgTxt: "Ürünün Asıl Fiyatı",
    deactiveCurrentPublicMsgTxt: "Public Olan Diğer Kuponu İptal Et",
    productFeeMsgTxt: "Ücret",
    feeIntervalMsgTxt: "Gün Sayısı",
    feeIntervalTexMsgTxt: "Zaman Aralığı",
    feeCurrencyMsgTxt: "Para Birimi",
    currencies: "TRY/USD",
    newVideoFilesMsgTxt: "Video Kapak Fotoğrafı (Görsel/Video)",
    productNameMsgTxt: "Ürün Adı",
    productIntervalMsgtxt: "Aralık",
    discountCouponMsgtxt: "İndirim Kuponu",
    discountCouponUpdateMsgtxt: "İndirim Kuponu Güncelle",
    couponUpdateMsgTxt: "Kupon Güncelleme",
    couponUpdateFormMsgTxt: "Kupon Güncelleme Formu",
    couponListMsgTxt: "Kupon Listesi",
    productCreationDateMsgTxt: "Oluşturulma Tarihi",
    newVideoUrlMsgTxt: "Video Url",
    newVideoAddMsgTxt: "Video Ekle",
    videoList: "Video Listesi",
    newVideoUpdateMsgTxt: "Video Güncelle",
    newVideoMaxFileWarningMsgTxt: "En fazla 1 dosya ekleyebilirsiniz!",
    uploadNewVideoFilesMsgTxt: "Video Görseli Yükle",
    blogActionsMsgTxt: "Blog Yazı İşlemleri",
    videoActionsMsgTxt: "Video İşlemleri",
    feeActionsMsgTxt: "Ürün Fiyat İşlemleri",
    blogListMsgTxt: "Blog Yazı Listesi",
    blogAddMsgTxt: "Blog Yazısı Ekle",
    videoListMsgTxt: "Video Listesi",
    updateBlogMsgTxt: "Blog Yazısı Güncelle",
    blogTitleMsgTxt: "Blog Başlığı",
    blogContentMsgTxt: "Blog İçeriği",
    blogUserNameMsgTxt: "Yazar Adı",
    blogUserLastnameMsgTxt: "Yazar Soyadı",
    blogFilesTitleMsgTxt: "Blog Dosyaları",
    uploadBlogUserPhotoMsgTxt: "Blog Yazar Fotoğrafı Yükle",
    uploadNewVideoPhotoMsgTxt: "Thumbnail Yükle",
    blogUserPhotoMsgTxt: "Blog Yazar Fotoğrafı",
    blogAuthorPhotoMsgTxt: "Yazar Fotoğrafı",
    blogFilesMsgTxt: "Blog Dosyaları (Görsel/Video) - (Maks. 4 dosya)",
    uploadBlogFilesMsgTxt: "Blog Görsel/Video Yükle",
    selectedFileFilesMsgTxt: "Seçilen Dosya/Dosyalar",
    selectedFileMsgTxt: "Seçilen Dosya",
    blogMaxFileWarningMsgTxt: "En fazla 4 dosya ekleyebilirsiniz!",
    videoMaxFileWarningMsgTxt: "En fazla 1 dosya ekleyebilirsiniz!",
    addVideoCoverImageMsgTxt: "Kapak Görseli Ekle",
    addVideoCoverImageTextMsgTxt: "için Kapak Görseli Ekle",
    videoCoverImageMsgTxt: "Video Kapak Görseli",
    noEmptyBlogContentMsgTxt: "Blog yazı içeriği boş olamaz!",
    maxBlogContentStringMsgTxt:
      "Blog yazısı için oluşturulan kod metni maksimum 20.000 karakter olabilir!",
    maxVideoContentStringMsgTxt:
      "Video için oluşturulan kod metni maksimum 20.000 karakter olabilir!",
    removeAllFilesCheckMsgTxt: "Bu blog yazısına ait tüm dosyalar silinsin",
    removeAuthorPhotoCheckMsgTxt: "Yazara ait fotoğraf silinsin",
    currentBlogFilesMsgTxt: "Mevcut Blog Dosyaları",
    fileMsgTxt: "Dosya",
    filesMsgTxt: "Dosya",
    filessMsgTxt: "Dosyalar",
    pleaseSelectContractFilesMsgTxt: "Sözleşme dosyası/dosyaları seçiniz.",
    fileFormatsCanBeMsgTxt:
      "Görsel (.jpg, png vs), Word ve PDF formatlarında dosya yükleyebilirsiniz.",
    fileDoesnotExist: "Dosya seçmediniz!",
    blogDetailMsgTxt: "Blog Detay",
    approveMsgTxt: "Onayla",
    notApproveMsgTxt: "Reddet",
    studentMsgTxt: "Öğrenci",
    teacherMsgTxt: "Öğretmen",
    corporationGroupNameMsgTxt: "Kurum Grup Adı",
    productCurrentPriceMsgTxt: "Ürünün Güncel Fiyatı Fiyatı",
    userIdMsgTxt: "Kullanıcı ID",
    startDateMsgTxt: "Başlama Tarihi",
    couponDeletedMsgTxt: "Kupon Silindi",
    endDateMsgTxt: "Bitiş Tarihi",
    maxCountMsgTxt: "Maksimum Kuponu Kullanacak Kullanıcı Sayısı",
    repeatedMaxCountMsgTxt: "Kuponun Maksimum Tekrar Kullanım Sayısı",
    descriptionMsgTxt: "Açıklama",
    isActiveMsgTxt: "Aktiflik Durumu",
    isPublicMsgTxt: "Public Durumu",
    thisIsPublicMsgTxt: "Public",
    thisIsNotPublicMsgTxt: "Public Değil",
    corporationActionsMsgTxt: "Kurum İşlemleri",
    createCouponFormMsgTxt: "Kupon Oluşturma Formu",
    corporationListMsgTxt: "Kurum Listesi",
    corporationAddMsgTxt: "Kurum Ekle",
    adminAddMsgTxt: "Admin Ekle",
    corporationsMsgTxt: "Kurumlar",
    corporationDetailMsgTxt: "Kurum Detay",
    subscriptionDetailsMsgTxt: "Abonelik Bilgileri",
    corporationSubscriptionMsgTxt: "Kurum Abonelik",
    updateCorporationMsgTxt: "Kurum Güncelle",
    corporationApplyListMsgTxt: "Kurum Başvuru Listesi",
    corporationApplyDetailMsgTxt: "Kurum Başvuru Detay",
    updateCorporationApplyMsgTxt: "Kurum Başvuru Görüntüle/Güncelle",
    updateCorporationInfosMsgTxt: "Kurum Bilgilerini Güncelle",
    updateCorporationAdminInfosMsgTxt: "Kurum Admin Bilgilerini Güncelle",
    companyUpdateInfoTextMsgTxt:
      "Kurumun başvurusuna ait açıklama ya da başvuru durumunu aşağıdaki form ile güncelleyebilirsiniz.",
    companyDomainInfoMsgTxt:
      "Kurum domain, kullanıcıların Foramind'a üyelik doğrulama olmadan sisteme giriş yapabilmesini sağlar.",
    actionDateMsgTxt: "İşlem Tarihi",
    startDateMsgTxt: "Başlangıç Tarihi",
    endDateMsgTxt: "Bitiş Tarihi",
    currentStartDateMsgTxt: "Mevcut Başlangıç Tarihi",
    currentEndDateMsgTxt: "Mevcut Bitiş Tarihi",
    createSubscriptionMsgTxt: "Abonelik Oluştur",
    updateSubscriptionMsgTxt: "Aboneliği Güncelle",
    subscriptionStartDateMsgTxt: "Abonelik Başlangıç Tarihi",
    subscriptionEndDateMsgTxt: "Abonelik Bitiş Tarihi",
    thereIsNoCompanySubscriptionMsgTxt:
      "Kuruma ait kurumsal abonelik kaydı bulunmamaktadır.",
    contractConditionMsgTxt: "Sözleşme Şartı",
    limitedMsgTxt: "Sınırlı",
    unlimitedMsgTxt: "Sınırsız",
    exampleMsgTxt: "Örnek",
    corporationIDMsgTxt: "Kurum ID",
    corporationNameMsgTxt: "Kurum Adı",
    corporationAdminMsgTxt: "Kontakt Ad Soyad",
    corporationAdminUpdateMsgTxt: "Kurum Adminleri",
    domainsNameMsgTxt: "Kurum Domainleri",
    domainsAddMsgTxt: "Kurum Ekstra Domain Ekle",
    adminsAddMsgTxt: "Kurum Admini Ekle",
    domainsAddRequirementMsg: "Lütfen kurum domainlerini virgül ile ayırınız",
    adminsAddRequirementMsg: "Lütfen adminleri virgül ile ayırınız",
    contactNameMsgTxt: "Kontakt Ad",
    contactSurnameMsgTxt: "Kontakt Soyad",
    contactFullnameMsgTxt: "Kontakt Ad Soyad",
    contactEmailMsgTxt: "Kontakt E-Posta",
    newContactEmailMsgTxt: "Yeni Kontakt E-Posta Ekle",
    userCountApproximateMsgTxt: "Yaklaşık Kullanıcı Sayısı",
    userCountMsgTxt: "Kullanıcı Sayısı",
    contactTelMsgTxt: "Kontakt Tel No",
    newContactTelMsgTxt: "Yeni Kontakt Tel No",
    companyDescMsgTxt: "Kurum Açıklama",
    contactDescriptionMsgTxt: "Yetkili (SuperAdmin) Yorumu",
    positiveMsgTxt: "Olumlu",
    negativeMsgTxt: "Olumsuz",
    waitingMsgTxt: "Bekliyor",
    uploadLogoMsgTxt: "Logo Yükle",
    corporationLogoMsgTxt: "Kurum Logo",
    imageMsgTxt: "Görsel",
    userImageMsgTxt: "Resim",
    uploadFileMsgTxt: "Dosya Yükle",
    uploadImageMsgTxt: "Resim Yükle",
    emailErrorMsgTxt: "Geçersiz mail adresi",
    profileMsgTxt: "Profil",
    adminUserFormMsgTxt: "Admin Kullanıcı Formu",
    foramindUserFormMsgTxt: "Kullanıcı (Foramind) Formu",
    pleaseSelectUserRoleForAddMsgTxt:
      "Eklemek istediğiniz kullanıcı rolünü seçiniz.",
    pleaseSelectUserForAiPackageMsgTxt:
      "Paket tanımlamak istediğiniz kullanıcı tipini seçiniz.",
    pleaseSelectCompanyForAiPackageMsgTxt:
      "Paket tanımlamak istediğiniz kurumu seçiniz.",
    pleaseSelectAiPackageMsgTxt: "Tanımlamak istediğiniz paketi seçiniz.",
    pleaseSelectUserTypeMsgTxt:
      "Tanımlamak istediğiniz kullanıcı tipini seçiniz.",
    pleaseSelectUserMsgTxt:
      "Tanımlamak istediğiniz kullanıcı mail adresini giriniz.",
    adminProfileMsgTxt: "Admin Profil",
    newVideoMsgTxt: "Video Destek",
    feeListMsgTxt: "Ürün Fiyat Listesi",
    packageListMsgTxt: "Paket Fiyat Listesi",
    assignPackageMsgTxt: "Kullanıcılara Paket Tanımla",
    feeUpdateMsgTxt: "Ürün Fiyat Güncellemesi",
    packageUpdateMsgTxt: "Paket Güncellemesi",
    packageCreateMsgTxt: "Paket oluştur",
    videoMsgTxt: "Video",
    logoutMsgTxt: "Çıkış",
    previousTextMsgTxt: "Önceki",
    nextTextMsgTxt: "Sonraki",
    pageTextMsgTxt: "Sayfa",
    rowsTextMsgTxt: "Satır",
    ofTextMsgTxt: ". sayfa",
    noDataTextMsgTxt: "Kayıt bulunmamaktadır",
    loadingTextMsgTxt: "Yükleniyor",
    showMsgTxt: "Göster",
    goDetailMsgTxt: "Detaya Git",
    updateMsgTxt: "Güncelle",
    showOrUpdateMsgTxt: "Görüntüle/Güncelle",
    makeActiveMsgTxt: "Aktif Yap",
    makePassiveMsgTxt: "Pasif Yap",
    deleteMsgTxt: "Sil",
    couponDeleteMsgTxt: "Kuponu Sil",
    nameFieldMsgTxt: "Isim",
    nameMsgTxt: "İsim",
    registerDateMsgTxt: "Kayıt Tarihi",
    addMsgTxt: "Ekle",
    saveMsgTxt: "Kaydet",
    errorMsgTxt: "Hata",
    infoMsgTxt: "Bilgilendirme",
    warningMsgTxt: "Uyarı",
    successMsgTxt: "Başarılı",
    cancelMsgTxt: "Kapat",
    sendMsgTxt: "Gönder",
    changePasswordMsgTxt: "Şifre Güncelle",
    currentPasswordMsgTxt: "Şifre",
    newPasswordMsgTxt: "Yeni Şifre",
    confirmPasswordMsgTxt: "Yeni Şifre Tekrar",
    passwordConfirmErrorMsgTxt: "Şifreler eşleşmiyor. Lütfen kontrol ediniz.",
    forgotPasswordInfoMsgTxt:
      "Mail adresinize şifre değiştirme linki göndereceğiz.",
    requiredMsgTxt: "Zorunlu alan",
    requiredForAdminRoleMsgTxt: "Kullanıcının rolü 'Admin' olmalı.",
    passwordTooShort: "Şifreniz en az 6 karakterden oluşmalı.",
    checkFormInfoMsgtxt: "Bilgileri doğru girdiğinizden emin olunuz.",
    itemsPerPageMsgTxt: "Bir Sayfadaki Kayıt",
    filterMsgTxt: "Filtre:",
    searchOnTableMsgTxt: "Tabloda Ara",
    noResultMsgTxt: "Kayıt bulunamadı",
    exportExcelMsgTxt: "Excel Çıktısı",
    defaultMsgTxt: "Varsayılan",
    corporationNameInfoMsgTxt:
      "Kurum seçmezseniz kullanıcı otomatik olarak 'Kafein' kurumuna eklenecektir.",
    selectCorporationMsgTxt: "Kurum Seçiniz",
    selectUserTypeMsgTxt: "Kullanıcı Tipi Seçiniz",
    removeUserTypeMsgTxt: "Kullanıcı Tipini Kaldır",
    selectDayMsgTxt: "Gün Sayısı Seçiniz",
    allOfMsgTxt: "Hepsi",
    selectMsgTxt: "Seçiniz",
    yesMsgTxt: "Evet",
    noMsgTxt: "Hayır",
    phoneMsgTxt: "Telefon",
    emailMsgTxt: "E-Posta",
    corporationAdminEmailMsgTxt: "Kurum Admin E-Posta",
    corporationAdminPasswordMsgTxt: "Kurum Admin Şifre",
    corporationServiceUrlMsgTxt: "Kurum Service URL",
    roleMsgTxt: "Rol",
    userTypeMsgTxt: "Kullanıcı Tipi",
    dayMsgTxt: "Gün",
    firstnameMsgTxt: "Ad",
    lastnameMsgTxt: "Soyad",
    createdDateMsgTxt: "Oluşturma Tarihi",
    createdByMsgTxt: "Oluşturan",
    updatedDateMsgTxt: "Güncelleme Tarihi",
    updatedByMsgTxt: "Güncelleyen",
    statusMsgTxt: "Durum",
    actionsMsgTxt: "İşlemler",
    documentProcessMsgTxt: "Döküman Kullanılabilirlik Süreci",
    visibilityMsgTxt: "Göster/Gizle",
    activeMsgTxt: "Aktif",
    corporationDocumentLimitWarningMsgTxt:
      "Kurum doküman yükleme alanı yetersiz",
    passiveMsgTxt: "Pasif",
    notFoundMsgTxt: "Bulunamadı",
    pleaseSelectCompanyMsgTxt:
      "Kullanıcı eklemeden önce sol üstteki listeden kurum seçmeniz gerekmektedir.",
    informationTitleMsgTxt: "BİLGİLENDİRME",
    superAdminInformationTextMsgTxt:
      "Kullanıcı ve kurum işlemlerini gerçekleştirmek için önce sol üstteki listeden kurum seçmelisiniz. Kurum seçtikten sonra ilgili kuruma ait kullanıcı/kurum işlemlerini gerçekleştirmek için soldaki menuden kullanıcı/kurum listesini görüntüleyebilir, ekleme ve güncelleme işlemlerini yapabilirsiniz.",
    companyAdminInformationTextMsgTxt:
      "Kurumunuza ait kullanıcı işlemlerini gerçekleştirmek için soldaki menüden kullanıcı listesini görüntüleyebilir, kullanıcı ekleme ve güncelleme işlemlerini yapabilirsiniz.",
    updateActionSuccessMsgTxt: "Güncelleme işlemi başarılı.",
    imageFileSizeMsgTxt: "Seçtiğiniz dosya boyutu en fazla 3 MB olabilir.",
    addedActionSuccessMsgTxt: "Ekleme işlemi başarılı.",
    willBeUploadedMsgTxt: "Yüklenecek",
    uploadedMsgTxt: "Yüklendi",
    nextUploadMsgtxt: "Sırada",
    passiveUploadedMsgTxt: "Pasif",
    errorUploadedMsgTxt: "Hata",
    processUploadedMsgTxt: "İşleniyor",
    deletedActionSuccessMsgTxt: "Silme işlemi başarılı.",
    corporationStatusUpdatedSuccessMsgTxt:
      "Kurumun statusu başarı ile güncellendi.",
    blogStatusUpdatedSuccessMsgTxt: "Blog'un statusu başarı ile güncellendi..",
    videoStatusUpdatedSuccessMsgTxt:
      "Video'nun statusu başarı ile güncellendi..",
    userStatusUpdatedSuccessMsgTxt:
      "Kullanıcının statusu başarı ile güncellendi.",
    makePassiveUserDoYouAcceptMsgTxt:
      "Bu kullanıcı pasif yapılacak. Kabul ediyor musunuz?",
    makePassiveCorporationDoYouAcceptMsgTxt:
      "Bu kurum pasif yapılacak. Kabul ediyor musunuz?",
    makePassiveBlogDoYouAcceptMsgTxt:
      "Bu blog yazısı pasif yapılacak. Kabul ediyor musunuz?",
    makePassiveVideoDoYouAcceptMsgTxt:
      "Bu video pasif yapılacak. Kabul ediyor musunuz?",
    loginInfoErrorMsgTxt:
      "E-posta ya da şifreniz hatalı, lütfen kontrol ediniz.",
    errorOccuredMsgTxt: "Bir hata ile karşılaşıldı, lütfen tekrar deneyiniz.",
    acceptFeedbackMsgTxt: "Yorumu Onayla",
    rejectFeedbackMsgTxt: "Yorumu Reddet",
    approveActionSuccessMsgTxt: "Yorum başarılı bir şekilde onaylanmıştır.",
    rejectActionSuccessMsgTxt: "Yorum başarılı bir şekilde reddedilmiştir.",
    makeApproveCommentDoYouAcceptMsgTxt:
      "Bu yorum onaylanacak. Kabul ediyor musunuz?",
    makeRejectCommentDoYouAcceptMsgTxt:
      "Bu yorum reddilecek. Kabul ediyor musunuz?",
  },

  en: {
    homepageMsgTxt: "Homepage",
    homeMsgTxt: "Home",
    copyUrlMsgTxt: "Copy URL",
    copyIDMsgTxt: "Copy ID",
    usersMsgTxt: "Users",
    userMsgTxt: "User",
    makeUserActiveProfileTxt: "Activate User",
    userAccountActivisionTxt: "User Profile Activated",
    updateUserMsgTxt: "Update User",
    settingsMsgTxt: "Settings",
    loginMsgTxt: "Login",
    discountCodeMsgTxt: "Discound Code",
    userIdMsgTxt: "User Id",
    signInMsgTxt: "Sign In to your account",
    enterUserNameMsgTxt: "Enter A User Name",
    usernameMsgTxt: "Username",
    passwordMsgTxt: "Password",
    forgotPasswordMsgTxt: "Forgot Password",
    menusMsgTxt: "Menus",
    makeYourChoiceMsgTxt: "Select",
    chatGptActions: "AI Actions",
    addChatGptPackage: "Add AI Packages",
    assignChatGptPackage: "Assign AI Packages",
    packageAddMsgTxt: "Add Package",
    activateUserTxt: "Activate User",
    userActionsMsgTxt: "User Actions",
    couponUpdateMsgTxt: "Coupon Update",
    userListMsgTxt: "User List",
    documentName: "Document Name",
    documentSize: "Document Size",
    documentAdd: "Add Document",
    fileName: "File Name",
    fileType: "File Type",
    documentProcessMsgTxt: "Document Availability Process",
    willBeUploadedMsgTxt: "Will be uploaded",
    uploadedMsgTxt: "Uploaded",
    nextUploadMsgtxt: "Next",
    passiveUploadedMsgTxt: "Passive",
    errorUploadedMsgTxt: "Error",
    processUploadedMsgTxt: "Processing",
    corporationDocumentLimitWarningMsgTxt:
      "Insufficient institution document upload space",
    contentName: "Content Name",
    userAddMsgTxt: "Add User",
    userTimeExtensionmsgTxt: "Update User Trial Day",
    documentOperationMsgTxt: "Document Operations",
    documentSizeLimitMsgTxt: "Document Storage Size",
    userDetailMsgTxt: "User Detail",
    currentUserCountInfoMsgTxt: "Current User Count",
    currentConditionMsgTxt: "Current Condition",
    deactiveCurrentPublicMsgTxt: "Make Deactive Current Public Coupon",
    commentsMsgTxt: "Comments",
    commentMsgTxt: "Comment",
    commentDetailMsgTxt: "Comment Detail",
    commentActionsMsgTxt: "Comment Actions",
    commentListMsgTxt: "Comment List",
    feedbacksMsgTxt: "Feedbacks",
    feedbackMsgTxt: "Feedback",
    feedbackListMsgTxt: "Feedback List",
    feedbackDetailMsgTxt: "Feedback Detail",
    feedbackActionsMsgTxt: "Feedback Actions",
    newVideoTitleMsgTxt: "Video Title",
    productFeeMsgTxt: "Price",
    feeIntervalMsgTxt: "Days",
    discountCouponMsgtxt: "Coupon Discount",
    discountCouponUpdateMsgtxt: "Update Coupon Discount",
    couponUpdateMsgTxt: "Update Coupon",
    couponUpdateFormMsgTxt: "Coupon Update Form",
    couponListMsgTxt: "Coupon List",
    feeIntervalTexMsgTxt: "Interval Time",
    feeCurrencyMsgTxt: "Currency",
    newVideoFilesMsgTxt: "Video Thumbnail (Image/Video)",
    productNameMsgTxt: "Product Name",
    productCreationDateMsgTxt: "Creation Date",
    productIntervalMsgtxt: "Interval",
    newVideoUrlMsgTxt: "Video Url",
    newVideoAddMsgTxt: "Add Video",
    videoList: "Video List",
    selectFileTypeMsgTxt: "Select File Type",
    newVideoUpdateMsgTxt: "Video Update",
    newVideoMaxFileWarningMsgTxt: "You can only upload a maximum of 1 files!",
    uploadNewVideoFilesMsgTxt: "Upload Video Image",
    blogActionsMsgTxt: "Blog Post Actions",
    videoActionsMsgTxt: "Video Actions",
    feeActionsMsgTxt: "Product Fee Actions",
    blogListMsgTxt: "Blog Post List",
    blogAddMsgTxt: "Add Blog Post",
    videoListMsgTxt: "Video List",
    updateBlogMsgTxt: "Update Blog Post",
    blogTitleMsgTxt: "Blog Title",
    blogContentMsgTxt: "Blog Content",
    blogUserNameMsgTxt: "Author Name",
    blogUserLastnameMsgTxt: "Author Lastname",
    blogFilesMsgTxt: "Blog Files",
    uploadBlogUserPhotoMsgTxt: "Upload Blog Author Photo",
    uploadNewVideoPhotoMsgTxt: "Upload Thumbnail",
    blogUserPhotoMsgTxt: "Blog Author Photo",
    blogAuthorPhotoMsgTxt: "Author Photo",
    blogFilesMsgTxt: "Blog Files (Image/Video) - (Max. 4 files)",
    uploadBlogFilesMsgTxt: "Upload Blog Image/Videos",
    selectedFileFilesMsgTxt: "Selected File/Files",
    selectedFileMsgTxt: "Selected File",
    blogMaxFileWarningMsgTxt: "You can only upload a maximum of 4 files!",
    videoMaxFileWarningMsgTxt: "You can only upload a maximum of 1 file!",
    addVideoCoverImageMsgTxt: "Add Cover Image",
    addVideoCoverImageTextMsgTxt: "add Cover Image for this",
    videoCoverImageMsgTxt: "Video Cover Image",
    noEmptyBlogContentMsgTxt: "Blog post content can not be empty!",
    maxBlogContentStringMsgTxt:
      "The code text generated for the blog post can be up to 20.000 characters!",
    maxVideoContentStringMsgTxt:
      "The code text generated for the video post can be up to 20.000 characters!",
    removeAllFilesCheckMsgTxt: "Delete all files of this blog post",
    removeAuthorPhotoCheckMsgTxt: "Delete blog author photo",
    currentBlogFilesMsgTxt: "Current Blog Files",
    fileMsgTxt: "File",
    filesMsgTxt: "Files",
    filessMsgTxt: "Files",
    pleaseSelectContractFilesMsgTxt: "Select the contract file(s).",
    fileFormatsCanBeMsgTxt:
      "You can upload files in image (.jpg, png etc.), Word and PDF formats.",
    fileDoesnotExist: "You did not upload any file!",
    blogDetailMsgTxt: "Blog Post Detail",
    approveMsgTxt: "Approve",
    notAproveMsgTxt: "Reject",
    studentMsgTxt: "Student",
    teacherMsgTxt: "Teacher",
    corporationGroupNameMsgTxt: "Corporation Group Name",
    corporationActionsMsgTxt: "Corporation Actions",
    corporationListMsgTxt: "Corporation List",
    corporationAddMsgTxt: "Add Corporation",
    adminAddMsgTxt: "Add Admin",
    corporationsMsgTxt: "Corporations",
    corporationDetailMsgTxt: "Corporation Detail",
    couponDeleteMsgTxt: "Delete Coupon",
    couponDeletedMsgTxt: "Coupon Deleted",
    subscriptionDetailsMsgTxt: "Subscription Details",
    corporationSubscriptionMsgTxt: "Corporation Subscription",
    updateCorporationMsgTxt: "Update Corporation",
    corporationApplyListMsgTxt: "Corporation Apply List",
    corporationApplyDetailMsgTxt: "Corporation Apply Detail",
    updateCorporationApplyMsgTxt: "Corporate Apply Show/Update",
    updateCorporationInfosMsgTxt: "Update Corporation Infos",
    updateCorporationAdminInfosMsgTxt: "Update Corporation Admin Infos",
    companyUpdateInfoTextMsgTxt:
      "You can update the explanation or application status of the company's application with the form below.",
    companyDomainInfoMsgTxt:
      "Company domain allows users to login to the system without verifying their membership to Foramind.",
    actionDateMsgTxt: "Action Date",
    startDateMsgTxt: "Start Date",
    endDateMsgTxt: "End Date",
    currentStartDateMsgTxt: "Current Start Date",
    currentEndDateMsgTxt: "Current End Date",
    createSubscriptionMsgTxt: "Create Subscription",
    createCouponFormMsgTxt: "Creating Coupon Form",
    updateSubscriptionMsgTxt: "Update Subscription",
    subscriptionStartDateMsgTxt: "Subscription Start Date",
    createCouponMsgTxt: "Create Coupon",
    addCouponMsgTxt: "Add Coupon",
    subscriptionEndDateMsgTxt: "Subscription End Date",
    thereIsNoCompanySubscriptionMsgTxt:
      "There is no company subscription record for this company.",
    contractConditionMsgTxt: "Contract Condition",
    limitedMsgTxt: "Limited",
    unlimitedMsgTxt: "Unlimited",
    deactiveCurrentPublicMsgTxt: "Deactive Current Public",
    exampleMsgTxt: "Example",
    corporationIDMsgTxt: "Corporation ID",
    corporationNameMsgTxt: "Corporation Name",
    corporationAdminMsgTxt: "Name Surname",
    corporationAdminUpdateMsgTxt: "Company Admins",
    domainsNameMsgTxt: "Corporation Domains",
    domainsAddMsgTxt: "Corporation Extra Domain Add",
    adminsAddMsgTxt: "Admin Add",
    domainsAddRequirementMsg: "Please enter corporation names with comma ",
    adminsAddRequirementMsg: "Please enter admins names with comma ",
    contactNameMsgTxt: "Contact Name",
    contactSurnameMsgTxt: "Contact Surname",
    contactFullnameMsgTxt: "Contact Fullname",
    contactEmailMsgTxt: "Contact E-Mail",

    userCountApproximateMsgTxt: "Approximate Number of Users",
    userCountMsgTxt: "Number of Users",
    contactTelMsgTxt: "Contact Tel",
    companyDescMsgTxt: "Corporation Description",
    contactDescriptionMsgTxt: "Contact (SuperAdmin) Description",
    positiveMsgTxt: "Positive",
    negativeMsgTxt: "Negative",
    waitingMsgTxt: "Waiting",
    uploadLogoMsgTxt: "Upload Logo",
    corporationLogoMsgTxt: "Corporation Logo",
    imageMsgTxt: "Image",
    userImageMsgTxt: "Image",
    uploadFileMsgTxt: "Upload File",
    uploadImageMsgTxt: "Upload Image",
    emailErrorMsgTxt: "Email adress format error",
    profileMsgTxt: "Profile",
    adminUserFormMsgTxt: "Admin User Form",
    foramindUserFormMsgTxt: "User (Foramind) Form",
    pleaseSelectUserRoleForAddMsgTxt:
      "Select the role of user you want to add.",
    pleaseSelectUserForAiPackageMsgTxt:
      "Select the user type for which you want to define a package.",
    pleaseSelectCompanyForAiPackageMsgTxt:
      "Select the company for which you want to define a package.",
    pleaseSelectAiPackageMsgTxt: "Select the package you want to define.",
    pleaseSelectUserTypeMsgTxt: "Select the user type you want to define.",
    adminProfileMsgTxt: "Admin Profile",
    newVideoMsgTxt: "Video Support",
    productCurrentPriceMsgTxt: "Product Current Price",
    userIdMsgTxt: "User ID",
    startDateMsgTxt: "Start Date",
    endDateMsgTxt: "Finish Date",
    maxCountMsgTxt: "Maximum User Count Whose Will Use Coupon",
    repeatedMaxCountMsgTxt: "Maximum Number of Coupon Reuses",
    descriptionMsgTxt: "Description",
    isActiveMsgTxt: "State of Active",
    isPublicMsgTxt: "State of Public",
    thisIsPublicMsgTxt: "Public",
    thisIsNotPublicMsgTxt: "Not Public",
    feeListMsgTxt: "Product Fee List",
    documentsMsgTxt: "Documents",
    feeUpdateMsgTxt: "Product Fee Update",
    packageUpdateMsgTxt: "Update Package",
    numberOfMaps: "Remaining Maps",
    packageNameMsgTxt: "Package Name",
    videoMsgTxt: "Video",
    logoutMsgTxt: "Log Out",
    previousTextMsgTxt: "Previous",
    nextTextMsgTxt: "Next",
    pageTextMsgTxt: "Page",
    rowsTextMsgTxt: "Row",
    ofTextMsgTxt: "of",
    noDataTextMsgTxt: "There is no data",
    loadingTextMsgTxt: "Loading",
    showMsgTxt: "Show",
    goDetailMsgTxt: "Show Detail",
    updateMsgTxt: "Update",
    showOrUpdateMsgTxt: "Show/Update",
    makeActiveMsgTxt: "Make Active",
    makePassiveMsgTxt: "Make Passive",
    productOrginalFeeMsgTxt: "Product Original Fee",
    forgotPasswordSendMsgTxt:
      "Informations about password reset has been sent to your e-mail address",
    deleteMsgTxt: "Delete",
    nameFieldMsgTxt: "name",
    nameMsgTxt: "Name",
    registerDateMsgTxt: "Register Date",
    forgotPasswordSendMsgTxt:
      "Informations about password reset has been sent to your e-mail address",
    addMsgTxt: "Add",
    saveMsgTxt: "Save",
    errorMsgTxt: "Error",
    infoMsgTxt: "Info",
    warningMsgTxt: "Warning",
    successMsgTxt: "Success",
    cancelMsgTxt: "Cancel",
    sendMsgTxt: "Send",
    changePasswordMsgTxt: "Change Password",
    currentPasswordMsgTxt: "Password",
    fileMsgTxt: "File",
    fileUploadMsgTxt: "Upload File",
    documentDeletedMsgTxt: "Document Deleted",
    newPasswordMsgTxt: "New Password",
    confirmPasswordMsgTxt: "New Password Confirm",
    passwordConfirmErrorMsgTxt:
      "Passwords do not match. Please check passwords.",
    forgotPasswordInfoMsgTxt:
      "We will send a link to change the password to your e-mail address.",
    requiredMsgTxt: "Required field",
    requiredForAdminRoleMsgTxt: "User's role should be 'Admin'",
    passwordTooShort: "Password is too short - should be 6 chars minimum.",
    checkFormInfoMsgtxt:
      "Please check that the information entered is correct.",
    itemsPerPageMsgTxt: "Items per page",
    filterMsgTxt: "Filter:",
    searchOnTableMsgTxt: "Search on table",
    noResultMsgTxt: "No Result",
    exportExcelMsgTxt: "Export Excel",
    defaultMsgTxt: "Default",
    corporationNameInfoMsgTxt:
      "If you do not select a company, the user will be automatically added to the 'Kafein'.",
    selectCorporationMsgTxt: "Select Corporation",
    selectUserTypeMsgTxt: "Select User Type",
    removeUserTypeMsgTxt: "Remove User Type",
    selectDayMsgTxt: "Select Number of Days",
    allOfMsgTxt: "",
    selectMsgTxt: "Select",
    yesMsgTxt: "Yes",
    noMsgTxt: "No",
    phoneMsgTxt: "Phone",
    emailMsgTxt: "Email",
    corporationAdminEmailMsgTxt: "Corporation Admin Email",
    corporationAdminPasswordMsgTxt: "Corporation Admin Password",
    corporationServiceUrlMsgTxt: "Corporation Service URL",
    roleMsgTxt: "Role",
    userTypeMsgTxt: "User Type",
    dayMsgTxt: "Day",
    firstnameMsgTxt: "First Name",
    lastnameMsgTxt: "Last Name",
    createdDateMsgTxt: "Created Date",
    createdByMsgTxt: "Created By",
    updatedDateMsgTxt: "Updated Date",
    updatedByMsgTxt: "Updated By",
    statusMsgTxt: "Status",
    documentOperationMsgTxt: "Document Operations",
    actionsMsgTxt: "Actions",
    visibilityMsgTxt: "Show/Hide",
    corporationDocumentMsgTxt: "Corporation Documents",
    activeMsgTxt: "Active",
    passiveMsgTxt: "Passive",
    notFoundMsgTxt: "Not Found",
    pleaseSelectCompanyMsgTxt:
      "Before adding a user, you need to select an institution from the list on the top left.",
    informationTitleMsgTxt: "INFORMATION",
    superAdminInformationTextMsgTxt:
      "To perform user and institution transactions, you must first select an institution from the list on the top left. After selecting an institution, you can view the user / institution list from the menu on the left, add and update operations in order to perform the user / institution transactions of the relevant institution.",
    companyAdminInformationTextMsgTxt:
      "You can view the user list from the menu on the left, add and update users in order to perform user operations for your institution.",
    updateActionSuccessMsgTxt: "Update action is successful.",
    imageFileSizeMsgTxt: "Your selected image file should be max 3 MB.",
    addedActionSuccessMsgTxt: "Added action is successful.",
    deletedActionSuccessMsgTxt: "Deleted action is successful.",
    corporationStatusUpdatedSuccessMsgTxt:
      "Company's status is updated successfully.",
    blogStatusUpdatedSuccessMsgTxt:
      "Blog Post's status is updated successfully.",
    videoStatusUpdatedSuccessMsgTxt: "Video's status is updated successfully.",
    userStatusUpdatedSuccessMsgTxt: "User's status is updated successfully.",
    makePassiveUserDoYouAcceptMsgTxt:
      "This user will be passive. Do you accept?",
    makePassiveCorporationDoYouAcceptMsgTxt:
      "This company will be passive. Do you accept?",
    makePassiveBlogDoYouAcceptMsgTxt:
      "This blog post will be passive. Do you accept?",
    makePassiveVideoDoYouAcceptMsgTxt:
      "This video post will be passive. Do you accept?",
    loginInfoErrorMsgTxt:
      "Email or password is incorrect, please check values.",
    errorOccuredMsgTxt: "An error occured, please retry.",
    acceptFeedbackMsgTxt: "Accept Feedback",
    rejectFeedbackMsgTxt: "Reject Feedback",
    approveActionSuccessMsgTxt: "The comment has been successfully approved.",
    rejectActionSuccessMsgTxt: "The comment has been successfully rejected.",
    makeApproveCommentDoYouAcceptMsgTxt:
      "This comment will be approve. Do you accept?",
    makeRejectCommentDoYouAcceptMsgTxt:
      "This comment will be reject. Do you accept?",
  },
};

export default Languages;
