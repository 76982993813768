import { corporationConstants } from "../constants";

let corporation = JSON.parse(localStorage.getItem("selectedCorporation"));

const initialState = {
  items: [],
  selectedCorporation: corporation ? corporation : {},
  detailedCorporation: {},
  updatedCorporaionAdmin: {},
  detailedCorporationApply: {},
  detailedCorporationSubscription: {},
  corporationSubscriptionInformations: {},
  companyAdmins: [],
  addCompanyAdminInfo: {},
  error: "",
};

export function corporations(state = initialState, action) {
  switch (action.type) {
    case corporationConstants.GETALL_CORPORATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        detailedCorporation: {},
      };
    case corporationConstants.GETALL_CORPORATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        detailedCorporation: {},
        error: "",
        items: action.corporations,
      };
    case corporationConstants.GETALL_CORPORATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        items: [],
        detailedCorporation: {},
        error: action.error,
      };

    case corporationConstants.GET_CORPORATION_ADMINS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case corporationConstants.GET_CORPORATION_ADMINS_SUCCESS:
      return {
        ...state,
        loading: false,
        companyAdmins: action.companyAdmins.data,
        error: ""
      };
    case corporationConstants.GET_CORPORATION_ADMINS_FAILURE:
      return {
        ...state,
        loading: false,
        companyAdmins: [],
        error: action.error,
      };

    case corporationConstants.GET_CORPORATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case corporationConstants.GET_CORPORATION_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedCorporation: action.selectedCorporation,
        error: "",
      };
    case corporationConstants.GET_CORPORATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        selectedCorporation: {},
      };
    case corporationConstants.GET_CORPORATION_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        detailedCorporation: {},
        detailedCorporationSubscription: {},
        error: "",
      };
    case corporationConstants.GET_CORPORATION_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        detailedCorporation: action.detailedCorporation.company,
        detailedCorporationSubscription:
          action.detailedCorporation.companySubscription,
        error: "",
      };
    case corporationConstants.GET_CORPORATION_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        detailedCorporation: {},
        detailedCorporationSubscription: {},
      };

    case corporationConstants.CORPORATION_SUBSCRIPTION_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        updatedCorporationSubscription: {},
        error: "",
      };
    case corporationConstants.CORPORATION_SUBSCRIPTION_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        updatedCorporationSubscription:
          action.updatedCorporationSubscription.companySubscription,
        error: "",
      };
    case corporationConstants.CORPORATION_SUBSCRIPTION_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        updatedCorporationSubscription: {},
      };

    case corporationConstants.ACTIVE_PASSIVE_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case corporationConstants.ACTIVE_PASSIVE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case corporationConstants.ACTIVE_PASSIVE_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case corporationConstants.CORPORATION_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case corporationConstants.CORPORATION_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        detailedCorporation: action,
      };
    case corporationConstants.CORPORATION_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case corporationConstants.CORPORATION_UPDATE_ADMİN_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case corporationConstants.CORPORATION_UPDATE_ADMİN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        detailedCorporation: action.admin,
      };
    case corporationConstants.CORPORATION_UPDATE_ADMİN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case corporationConstants.CORPORATION_UPDATE_INFOS_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case corporationConstants.CORPORATION_UPDATE_INFOS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case corporationConstants.CORPORATION_UPDATE_INFOS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case corporationConstants.CORPORATION_ADD_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case corporationConstants.CORPORATION_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case corporationConstants.CORPORATION_ADD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case corporationConstants.GET_CORPORATION_APPLY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        detailedCorporationApply: {},
      };
    case corporationConstants.GET_CORPORATION_APPLY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        detailedCorporationApply: {},
        error: "",
        items: action.applyList,
      };
    case corporationConstants.GET_CORPORATION_APPLY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        items: [],
        detailedCorporationApply: {},
        error: action.error,
      };
    case corporationConstants.GET_CORPORATION_APPLY_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        detailedCorporationApply: {},
        error: "",
      };
    case corporationConstants.GET_CORPORATION_APPLY_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        detailedCorporationApply: action.detailedCorporationApply,
        error: "",
      };
    case corporationConstants.GET_CORPORATION_APPLY_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        detailedCorporationApply: {},
      };
    case corporationConstants.CORPORATION_APPLY_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case corporationConstants.CORPORATION_APPLY_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        detailedCorporationApply: action.updatedCorporationApply.data,
      };
    case corporationConstants.CORPORATION_APPLY_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case corporationConstants.CORPORATION_SUBSCRIPTION_ADD_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case corporationConstants.CORPORATION_SUBSCRIPTION_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case corporationConstants.CORPORATION_SUBSCRIPTION_ADD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case corporationConstants.GET_CORPORATION_SUBSCRIPTION_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        corporationSubscriptionInformations: {},
        error: "",
      };
    case corporationConstants.GET_CORPORATION_SUBSCRIPTION_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        corporationSubscriptionInformations:
          action.corporationSubscriptionInformations,
        error: "",
      };
    case corporationConstants.GET_CORPORATION_SUBSCRIPTION_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        corporationSubscriptionInformations: {},
      };

    case corporationConstants.ADD_COMPANY_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
        addCompanyAdminInfo: {},
        error: "",
      };
    case corporationConstants.ADD_COMPANY_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        addCompanyAdminInfo:
        action.addCompanyAdmin,
        error: "",
      };
    case corporationConstants.ADD_COMPANY_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        addCompanyAdminInfo: {},
      };
    default:
      return state;
  }
}
