export const chatGptConstants = {
    GETALL_PACKAGES_REQUEST: "PACKAGES_GETALL_REQUEST",
    GETALL_PACKAGES_SUCCESS: "PACKAGES_GETALL_SUCCESS",
    GETALL_PACKAGES_FAILURE: "PACKAGES_GETALL_FAILURE",

    GET_PACKAGE_DETAIL_REQUEST: "PACKAGE_DETAIL_GET_REQUEST",
    GET_PACKAGE_DETAIL_SUCCESS: "PACKAGE_DETAIL_GET_SUCCESS",
    GET_PACKAGE_DETAIL_FAILURE: "PACKAGE_DETAIL_GET_FAILURE",

    PACKAGE_UPDATE_REQUEST: "PACKAGE_UPDATE_REQUEST",
    PACKAGE_UPDATE_SUCCESS: "PACKAGE_UPDATE_SUCCESS",
    PACKAGE_UPDATE_FAILURE: "PACKAGE_UPDATE_FAILURE",

    PACKAGE_ADD_REQUEST: "PACKAGE_ADD_REQUEST",
    PACKAGE_ADD_SUCCESS: "PACKAGE_ADD_SUCCESS",
    PACKAGE_ADD_FAILURE: "PACKAGE_ADD_FAILURE",

  };
  