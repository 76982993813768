export const corporationConstants = {
  GETALL_CORPORATIONS_REQUEST: "CORPORATIONS_GETALL_REQUEST",
  GETALL_CORPORATIONS_SUCCESS: "CORPORATIONS_GETALL_SUCCESS",
  GETALL_CORPORATIONS_FAILURE: "CORPORATIONS_GETALL_FAILURE",

  GET_CORPORATION_REQUEST: "CORPORATION_GET_REQUEST",
  GET_CORPORATION_SUCCESS: "CORPORATION_GET_SUCCESS",
  GET_CORPORATION_FAILURE: "CORPORATION_GET_FAILURE",

  GET_CORPORATION_DETAIL_REQUEST: "CORPORATION_DETAIL_GET_REQUEST",
  GET_CORPORATION_DETAIL_SUCCESS: "CORPORATION_DETAIL_GET_SUCCESS",
  GET_CORPORATION_DETAIL_FAILURE: "CORPORATION_DETAIL_GET_FAILURE",

  DELETE_COMPANY_ADMIN_REQUEST: "DELETE_COMPANY_ADMIN_REQUEST",
  DELETE_COMPANY_ADMIN_SUCCESS: "CORPORATION_DETAIL_GET_SUCCESS",
  DELETE_COMPANY_ADMIN_FAILURE: "DELETE_COMPANY_ADMIN_FAILURE",

  ADD_COMPANY_ADMIN_REQUEST: "DELETE_COMPANY_ADMIN_REQUEST",
  ADD_COMPANY_ADMIN_SUCCESS: "CORPORATION_DETAIL_GET_SUCCESS",
  ADD_COMPANY_ADMIN_FAILURE: "DELETE_COMPANY_ADMIN_FAILURE",

  GET_CORPORATION_ADMINS_REQUEST: "GET_CORPORATION_APPLY_DETAIL_REQUEST",
  GET_CORPORATION_ADMINS_SUCCESS: "GET_CORPORATION_APPLY_DETAIL_SUCCESS",
  GET_CORPORATION_ADMINS_FAILURE: "GET_CORPORATION_APPLY_DETAIL_FAILURE",

  ACTIVE_PASSIVE_COMPANY_REQUEST: "COMPANY_ACTIVE_PASSIVE_REQUEST",
  ACTIVE_PASSIVE_COMPANY_SUCCESS: "COMPANY_ACTIVE_PASSIVE_SUCCESS",
  ACTIVE_PASSIVE_COMPANY_FAILURE: "COMPANY_ACTIVE_PASSIVE_FAILURE",

  CORPORATION_UPDATE_REQUEST: "CORPORATION_UPDATE_REQUEST",
  CORPORATION_UPDATE_SUCCESS: "CORPORATION_UPDATE_SUCCESS",
  CORPORATION_UPDATE_FAILURE: "CORPORATION_UPDATE_FAILURE",

  CORPORATION_UPDATE_INFOS_REQUEST: "CORPORATION_UPDATE_INFOS_REQUEST",
  CORPORATION_UPDATE_INFOS_SUCCESS: "CORPORATION_UPDATE_INFOS_SUCCESS",
  CORPORATION_UPDATE_INFOS_FAILURE: "CORPORATION_UPDATE_INFOS_FAILURE",

  CORPORATION_ADD_REQUEST: "CORPORATION_ADD_REQUEST",
  CORPORATION_ADD_SUCCESS: "CORPORATION_ADD_SUCCESS",
  CORPORATION_ADD_FAILURE: "CORPORATION_ADD_FAILURE",

  GET_CORPORATION_APPLY_LIST_REQUEST: "GET_CORPORATION_APPLY_LIST_REQUEST",
  GET_CORPORATION_APPLY_LIST_SUCCESS: "GET_CORPORATION_APPLY_LIST_SUCCESS",
  GET_CORPORATION_APPLY_LIST_FAILURE: "GET_CORPORATION_APPLY_LIST_FAILURE",

  GET_CORPORATION_APPLY_DETAIL_REQUEST: "GET_CORPORATION_APPLY_DETAIL_REQUEST",
  GET_CORPORATION_APPLY_DETAIL_SUCCESS: "GET_CORPORATION_APPLY_DETAIL_SUCCESS",
  GET_CORPORATION_APPLY_DETAIL_FAILURE: "GET_CORPORATION_APPLY_DETAIL_FAILURE",

  CORPORATION_UPDATE_ADMİN_REQUEST: "CORPORATION_UPDATE_ADMİN_REQUEST",
  CORPORATION_UPDATE_ADMİN_SUCCESS: "CORPORATION_UPDATE_ADMİN_SUCCESS",
  CORPORATION_UPDATE_ADMİN_FAILURE: "CORPORATION_UPDATE_ADMİN_FAILURE",

  CORPORATION_APPLY_UPDATE_REQUEST: "CORPORATION_APPLY_UPDATE_REQUEST",
  CORPORATION_APPLY_UPDATE_SUCCESS: "CORPORATION_APPLY_UPDATE_SUCCESS",
  CORPORATION_APPLY_UPDATE_FAILURE: "CORPORATION_APPLY_UPDATE_FAILURE",

  CORPORATION_SUBSCRIPTION_ADD_REQUEST: "CORPORATION_SUBSCRIPTION_ADD_REQUEST",
  CORPORATION_SUBSCRIPTION_ADD_SUCCESS: "CORPORATION_SUBSCRIPTION_ADD_SUCCESS",
  CORPORATION_SUBSCRIPTION_ADD_FAILURE: "CORPORATION_SUBSCRIPTION_ADD_FAILURE",

  CORPORATION_SUBSCRIPTION_UPDATE_REQUEST:
    "CORPORATION_SUBSCRIPTION_UPDATE_REQUEST",
  CORPORATION_SUBSCRIPTION_UPDATE_SUCCESS:
    "CORPORATION_SUBSCRIPTION_UPDATE_SUCCESS",
  CORPORATION_SUBSCRIPTION_UPDATE_FAILURE:
    "CORPORATION_SUBSCRIPTION_UPDATE_FAILURE",

  GET_CORPORATION_SUBSCRIPTION_INFO_REQUEST:
    "GET_CORPORATION_SUBSCRIPTION_INFO_REQUEST",
  GET_CORPORATION_SUBSCRIPTION_INFO_SUCCESS:
    "GET_CORPORATION_SUBSCRIPTION_INFO_SUCCESS",
  GET_CORPORATION_SUBSCRIPTION_INFO_FAILURE:
    "GET_CORPORATION_SUBSCRIPTION_INFO_FAILURE",
};
