import { combineReducers } from "redux";
import { sidebar } from "./sidebar";
import { authentication } from "./authentication";
import { corporations } from "./corporations";
import { feedbacks } from "./feedbacks";
import { profile } from "./profile";
import { alert } from "./alert";
import { modal } from "./modal";
import { blogs } from "./blogs";
import { video } from "./video";
import { fee } from "./fee";
import { packages } from "./chatgpt";
import { users } from "./users";
import { coupon } from "./coupon";
import { document } from "./document";

const rootReducer = combineReducers({
  sidebar,
  users,
  corporations,
  authentication,
  feedbacks,
  profile,
  alert,
  modal,
  blogs,
  video,
  fee,
  packages,
  coupon,
  document,
});

export default rootReducer;
