export const profileConstants = {
  GET_PROFILE_REQUEST: 'PROFILE_GET_REQUEST',
  GET_PROFILE_SUCCESS: 'PROFILE_GET_SUCCESS',
  GET_PROFILE_FAILURE: 'PROFILE_GET_FAILURE',

  PROFILE_UPDATE_REQUEST: 'PROFILE_UPDATE_REQUEST',
  PROFILE_UPDATE_SUCCESS: 'PROFILE_UPDATE_SUCCESS',
  PROFILE_UPDATE_FAILURE: 'PROFILE_UPDATE_FAILURE',

  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE'
};
