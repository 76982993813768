import { authHeader } from "../helpers";
import { userService } from "./index";
import Resources from "../libraries/resources";
import Languages from "../libraries/languages";

const apiBaseURL = Resources.getValue("apiBaseEnvURL");

export const documentService = {
  getDocument,
  updateDocument,
  deleteDocument,
  getDocumentRemainSpaceById,
};

function getDocument(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("getDocument")}?companyId=${id}`,
    requestOptions
  ).then(handleResponse);
}
function getDocumentRemainSpaceById(companyId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue(
      "getRemainingDataSpaceById"
    )}?companyId=${companyId}`,
    requestOptions
  ).then(handleResponse);
}

function updateDocument(param) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(param),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("uploadDocument")}`,
    requestOptions
  ).then(handleResponse);
}

function deleteDocument(documentId) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("deleteDocument")}?documentId=${parseInt(
      documentId
    )}`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
      }

      const error =
        (data &&
          (data.data.includes("Kurum doküman Limiti yetersiz")
            ? Languages.getValue("corporationDocumentLimitWarningMsgTxt")
            : data.message)) ||
        (data && data.errors && data.errors[0]) ||
        response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
