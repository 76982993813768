import TheContent from './TheContent'
import TheFooter from './TheFooter'
import TheHeader from './TheHeader'
import TheHeaderDropdown from './TheHeaderDropdown'
import TheHeaderDropdownMssg from './TheHeaderDropdownMssg'
import TheHeaderDropdownNotif from './TheHeaderDropdownNotif'
import TheHeaderDropdownTasks from './TheHeaderDropdownTasks'
import TheHeaderDropdownLanguage from './TheHeaderDropdownLanguage'
import TheLayout from './TheLayout'
import TheSidebar from './TheSidebar'

export {
  TheContent,
  TheFooter,
  TheHeader,
  TheHeaderDropdown,
  TheHeaderDropdownMssg,
  TheHeaderDropdownNotif,
  TheHeaderDropdownTasks,
  TheHeaderDropdownLanguage,
  TheLayout,
  TheSidebar
}
