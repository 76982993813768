import { authHeader } from '../helpers';
import { userService } from './index';
import Resources from '../libraries/resources';

const apiBaseURL = Resources.getValue("apiBaseEnvURL");

export const profileService = {
  getProfile,
  updateProfile,
  changePassword
};

function getProfile(id) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue('getUserProfileDetailApiURL')}?userId=${id}`, requestOptions).then(handleResponse);
}

function updateProfile(profileData) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(profileData)
  };

  return fetch(`${apiBaseURL}${Resources.getValue('updateUserProfileApiURL')}`, requestOptions).then(handleResponse);
}

function changePassword(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetch(`${apiBaseURL}${Resources.getValue('changePasswordApiURL')}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        if (localStorage.getItem("adminUserInfo")) {
          userService.logout();
        }

      }

      const error = (data && data.message) || (data && data.errors && data.errors[0]) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
