import { blogConstants } from '../constants';
import { blogService } from '../services';
import { modalActions } from './';
import Languages from '../libraries/languages';
import Resources from '../libraries/resources';

export const blogActions = {
  getAllBlogs,
  getBlogDetailById,
  addBlog,
  updateBlog,
  makeBlogActivePassive
};

function getAllBlogs() {
  return dispatch => {
    dispatch(request());

    blogService.getBlogs()
      .then(
        blogs => {
          dispatch(success(blogs.blogModel));
        },
        error => {
          // dispatch(modalActions.error(''));
          // dispatch(modalActions.error(Languages.getValue(error)));
          dispatch(failure(error.toString()))
        }
      );
  };

  function request() { return { type: blogConstants.GETALL_BLOGS_REQUEST } }
  function success(blogs) { return { type: blogConstants.GETALL_BLOGS_SUCCESS, blogs } }
  function failure(error) { return { type: blogConstants.GETALL_BLOGS_FAILURE, error } }
}

function addBlog(addedBlog) {
  return dispatch => {
    dispatch(request(addedBlog));

    blogService.addBlog(addedBlog)
      .then(
        addedBlog => {
          dispatch(modalActions.success(''));
          dispatch(success(addedBlog));
          dispatch(modalActions.success(Languages.getValue('addedActionSuccessMsgTxt')));
          window.location.href = Resources.getValue('appBaseEnvURL') + '/#/blogs';
        },
        error => {
          dispatch(modalActions.error(''));
          dispatch(modalActions.error(error));
          dispatch(failure(error.toString()));
        }
      );
  };

  function request(addedBlog) { return { type: blogConstants.BLOG_ADD_REQUEST, addedBlog } }
  function success(addedBlog) { return { type: blogConstants.BLOG_ADD_SUCCESS, addedBlog } }
  function failure(error) { return { type: blogConstants.BLOG_ADD_FAILURE, error } }
}

function getBlogDetailById(id) {
  return dispatch => {
    dispatch(request());

    blogService.getBlogById(id)
      .then(
        (blogInfo) => {
          dispatch(success(blogInfo));
        },
        error => {
          dispatch(modalActions.error(''));
          dispatch(modalActions.error(error));
          dispatch(failure(error.toString()))
        }
      );
  };

  function request() { return { type: blogConstants.GET_BLOG_DETAIL_REQUEST } }
  function success(detailedBlog) { return { type: blogConstants.GET_BLOG_DETAIL_SUCCESS, detailedBlog } }
  function failure(error) { return { type: blogConstants.GET_BLOG_DETAIL_FAILURE, error } }
}

function updateBlog(blog) {
  return dispatch => {
    dispatch(request());

    blogService.updateBlog(blog)
      .then(
        blog => {
          dispatch(modalActions.success(''));
          dispatch(success(blog));
          dispatch(modalActions.success(Languages.getValue('updateActionSuccessMsgTxt')));
          // window.location.reload();
        },
        error => {
          dispatch(modalActions.error(''));
          dispatch(modalActions.error(error ? error.toString() : Languages.getValue('updateActionSuccessMsgTxt')));
          dispatch(failure(error.toString()));
        }
      );
  };

  function request() { return { type: blogConstants.BLOG_UPDATE_REQUEST } }
  function success(updatedBlog) { return { type: blogConstants.BLOG_UPDATE_SUCCESS, updatedBlog } }
  function failure(error) { return { type: blogConstants.BLOG_UPDATE_FAILURE, error } }
}

function makeBlogActivePassive(id, isActive) {
  return dispatch => {
    dispatch(request());

    blogService.makeBlogActivePassive(id, isActive)
      .then(
        () => {
          dispatch(modalActions.success(''));
          dispatch(success());
          dispatch(modalActions.success(Languages.getValue('blogStatusUpdatedSuccessMsgTxt')));
          dispatch(getAllBlogs());
        },
        error => {
          dispatch(modalActions.error(''));
          dispatch(modalActions.error(error));
          dispatch(failure(error.toString()))
        }
      );
  };

  function request() { return { type: blogConstants.ACTIVE_PASSIVE_BLOG_REQUEST } }
  function success() { return { type: blogConstants.ACTIVE_PASSIVE_BLOG_SUCCESS } }
  function failure(error) { return { type: blogConstants.ACTIVE_PASSIVE_BLOG_FAILURE, error } }
}
