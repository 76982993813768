import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  CButton,
  CCol,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow
} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import CIcon from '@coreui/icons-react'
import Modal from './modal';
import Languages from "../libraries/languages";

import { Formik } from 'formik';
import * as Yup from "yup";

// Images
import visibleIcon from "../assets/img/visible-icon.png";
import visibleOffIcon from "../assets/img/visible-off-icon.png";

// Actions
import { userActions } from '../actions';

const LoginForm = () => {
  const [user, setUser] = useState({
    email: '',
    password: ''
  });
  const [passwordInputType, setPasswordInputType] = useState('password');
  const loggingIn = useSelector(state => state.authentication.loggingIn);
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState("");

  const LoginFunc = (details) => {
    setErrorMsg('');
    if (details.email && details.password) {
      dispatch(userActions.login(details.email, details.password));
    } else {
      setErrorMsg(Languages.getValue('checkFormInfoMsgtxt'));
    }
  }

  const changeVisible = () => {
    var passInput = document.querySelector("#password");
    if (passInput.type === "password") {
      passInput.type = "text";
      setPasswordInputType(passInput.type);
    } else {
      passInput.type = "password";
      setPasswordInputType(passInput.type);
    }
  }

  return (
    <>
      <Modal type="warning" message={errorMsg} />
      <Formik
        initialValues={user}
        onSubmit={(values, { setSubmitting }) => {
          LoginFunc(values);
          setSubmitting(false);
        }}

        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email()
            .required(Languages.getValue('requiredMsgTxt')),
          password: Yup.string()
            .required(Languages.getValue('requiredMsgTxt'))
        })}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit
          } = props;
          return (
            <form onSubmit={handleSubmit}>
              <h1>{Languages.getValue('loginMsgTxt')}</h1>
              <p className="text-muted">{Languages.getValue('signInMsgTxt')}</p>
              <CInputGroup className="mb-3">
                <CInputGroupPrepend>
                  <CInputGroupText>
                    <CIcon name="cil-user" />
                  </CInputGroupText>
                </CInputGroupPrepend>
                <CInput
                  id="email"
                  type="email"
                  name="email"
                  placeholder={Languages.getValue('emailMsgTxt')}
                  value={values.email}
                  onChange={(e) => {
                    handleChange(e);
                    const { name, value } = e.target;
                    setUser(user => ({ ...user, [name]: value }));
                  }}
                  onBlur={handleBlur}
                  className={errors.email && touched.email && "error"}
                />
                {errors.email && touched.email && (
                  <div className="input-feedback w-100 text-danger">{errors.email}</div>
                )}
              </CInputGroup>
              <CInputGroup className="mb-4 password-with-visibility">
                <CInputGroupPrepend>
                  <CInputGroupText>
                    <CIcon name="cil-lock-locked" />
                  </CInputGroupText>
                </CInputGroupPrepend>
                <CInput
                  id="password"
                  type="password"
                  name="password"
                  placeholder={Languages.getValue('passwordMsgTxt')}
                  value={values.password}
                  onChange={(e) => {
                    handleChange(e);
                    const { name, value } = e.target;
                    setUser(user => ({ ...user, [name]: value }));
                  }}
                  onBlur={handleBlur}
                  className={errors.password && touched.password && "error"}
                />
                <img
                  src={passwordInputType === 'text' ? visibleIcon : visibleOffIcon}
                  alt={Languages.getValue('visibilityMsgTxt')}
                  onClick={changeVisible}
                />
                {errors.password && touched.password && (
                  <div className="input-feedback w-100 text-danger">{errors.password}</div>
                )}
              </CInputGroup>
              <CRow>
                <CCol xs="6">
                  <CButton type="submit" color="primary" className="px-4" disabled={isSubmitting}>
                    {loggingIn && <span className="spinner-border spinner-border-sm mr-1"></span>}
                    {Languages.getValue('loginMsgTxt')}
                  </CButton>
                </CCol>
                <CCol xs="6" className="text-right">
                  <Link to="/forgot-password" className="px-0">{Languages.getValue('forgotPasswordMsgTxt')}</Link>
                </CCol>
              </CRow>
            </form>
          );
        }}
      </Formik>
    </>
  )
}

export default LoginForm;
