import { authHeader } from '../helpers';
import { userService } from './index';
import Resources from '../libraries/resources';

const apiBaseURL = Resources.getValue("apiBaseEnvURL");

export const blogService = {
  getBlogs,
  getBlogById,
  updateBlog,
  addBlog,
  makeBlogActivePassive
};

function getBlogs() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue('getBlogsApiURL')}`,
    requestOptions
  ).then(handleResponse);
}

function addBlog(blog) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(blog)
  };

  return fetch(`${apiBaseURL}${Resources.getValue('addBlogApiURL')}`, requestOptions).then(handleResponse);
}

function getBlogById(id) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader()},
  };

  return fetch(`${apiBaseURL}${Resources.getValue('getBlogByIdApiURL')}?blogId=${id}`, requestOptions).then(handleResponse);
}

function updateBlog(blog) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(blog)
  };

  return fetch(`${apiBaseURL}${Resources.getValue('updateBlogApiURL')}`, requestOptions).then(handleResponse);
}

function makeBlogActivePassive(id, isActive) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader()
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue('makeActiveOrPassiveBlogApiURL')}?blogId=${id}&isActive=${isActive}`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
      }

      const error = (data && data.message) || (data && data.errors && data.errors[0]) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
