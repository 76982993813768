export const feeConstants = {
  GETALL_FEE_REQUEST: "FEES_GETALL_REQUEST",
  GETALL_FEE_SUCCESS: "FEES_GETALL_SUCCESS",
  GETALL_FEE_FAILURE: "FEES_GETALL_FAILURE",

  GET_FEE_DETAIL_REQUEST: "FEE_DETAIL_GET_REQUEST",
  GET_FEE_DETAIL_SUCCESS: "FEE_DETAIL_GET_SUCCESS",
  GET_FEE_DETAIL_FAILURE: "FEE_DETAIL_GET_FAILURE",

  FEE_UPDATE_REQUEST: "FEE_UPDATE_REQUEST",
  FEE_UPDATE_SUCCESS: "FEE_UPDATE_SUCCESS",
  FEE_UPDATE_FAILURE: "FEE_UPDATE_FAILURE",
};
