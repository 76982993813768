import { authHeader } from "../helpers";
import { userService } from "./index";
import Resources from "../libraries/resources";

const apiBaseURL = Resources.getValue("apiBaseEnvURL");

export const videoService = {
  CreateVideoInfo,
  getVideo,
  updateVideo,
  getVideoById,
  makeVideoActivePassive
};

function makeVideoActivePassive(id, isActive) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue('makeActiveOrPassiveVideoApiURL')}?videoInfoId=${id}&isActive=${isActive}`,
    requestOptions
  ).then(handleResponse);
}


function CreateVideoInfo(video) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(video),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("videoCreateInfoApiURL")}`,
    requestOptions
  ).then(handleResponse);
}

function getVideo() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("getVideosApiURL")}`,
    requestOptions
  ).then(handleResponse);
}

function updateVideo(video) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(video),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("updateVideoApiURL")}`,
    requestOptions
  ).then(handleResponse);
}

function getVideoById(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("getVideoByIdApiUrl")}?videoInfoId=${id}`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
      }

      const error =
        (data && data.message) ||
        (data && data.errors && data.errors[0]) ||
        response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
