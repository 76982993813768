var currentEnv = (function () {
  var env = process.env.REACT_APP_ENVIRONMENT || "local";
  return env;
})();

var Resources = {
  getValue: function (key) {
    if (typeof Resources["url"][key] != "undefined")
      return Resources["url"][key];
    else if (typeof Resources[currentEnv][key] != "undefined")
      return Resources[currentEnv][key];
  },

  url: {
    accountLoginApiURL: "/Account/AdminLogin",
    accountLogoutApiURL: "/Account/Logout",
    getUsersApiURL: "/Login/GetUsers",
    addUserApiURL: "/Login/CreateUser", // kurum admin ve super admin icin foramind kullanicisi ekler
    addUserToCorporationApiURL: "/Company/CreateCompanyUser", // super admin icin admin kullanicisi ekler
    updateUserApiURL: "/Login/UpdateUser",
    getUserByIdApiURL: "/Login/GetUserById",
    makeActiveOrPassiveUserApiURL: "/Login/MakePassiveOrActiveUser",
    getCorporationsApiURL: "/Company/GetAllCompany",
    getCorporationByIdApiURL: "/Login/GetCompanyById",
    updateCorporationApiURL: "/Company/UpdateCompany", // Super admin kurumu guncellerken
    updateCorporationInfosApiURL: "/Login/UpdateCompany", // login olan kurum admin kendi kurumunu guncellerken
    addCorporationApiURL: "/Company/CreateCompany",
    makeActiveOrPassiveCorporationApiURL: "/Company/MakeActivePassiveCompany",
    getUserProfileDetailApiURL: "/Login/GetUserById",
    updateUserProfileApiURL: "/Login/UpdateUser",
    changePasswordApiURL: "/Login/ChangePassword",
    getFeedbackListApiURL: "/Task/GetReviewTaskList",
    approveFeedbackApiUrl: "/Task/AcceptApprovalOfReview",
    rejectFeedbackApiUrl: "/Task/RejectApprovalOfReview",
    getAllUserTypesApiURL: "/User/GetAllUserTypes",
    addBlogApiURL: "/Blog/CreateBlog",
    getBlogsApiURL: "/Blog/GetBlogList",
    getBlogByIdApiURL: "/Blog/GetBlogModelById",
    updateBlogApiURL: "/Blog/UpdateBlog",
    makeActiveOrPassiveBlogApiURL: "/Blog/MakeActivePassiveBlog",
    getCorporationApplyListApiURL: "/CorporateSubscription/GetAll",
    getCorporationApplyByIdApiURL:
      "/CorporateSubscription/GetSubscriptionRecourseBydId",
    updateCorporationApplyInfosApiURL: "/CorporateSubscription/Update",
    createCompanySubscriptionApiURL: "/Company/CreateCompanySubscription",
    updateCompanySubscriptionModelApiURL: "/Company/UpdateCompanySubscription",
    getCompanySubscriptionDetailsApiURL: "/Company/GetCompanySubscription",
    addCompanyAdminApiURL: "/Company/AddCompanyAdmin",
    deleteCompanyAdminApiURL: "/Company/DeleteCompanyAdmin",
    getCompanyAdminsApiURL: "/Company/GetCompanyAdmins",
    updateUserTimeExtensionApiURL: "/User/UpdateTrialDayEndDate",
    videoCreateInfoApiURL: "/VideoInfo/CreateVideoInfo",
    getVideosApiURL: "/VideoInfo/GetVideoInfoList",
    updateVideoApiURL: "/VideoInfo/UpdateVideoInfo",
    getVideoByIdApiUrl: "/VideoInfo/GetVideoInfoById",
    makeActiveOrPassiveVideoApiURL: "/VideoInfo/UpdateVideoInfoActiveStatus",
    getFeesApiURL: "/Product/GetProducts",
    getFeesByIdApiUrl: "/Product/GetProductDetailById",
    updateFeesApiURL: "/Product/UpdateProductPrice",
    accountConfirmUserURL: "/Account/ConfirmUserAsync",
    createMapAiPackage: "/MapAiPackage/CreateMapAiPackage",
    assignAiMapToUser: "/MapAiPackage/AssignAiMapToUser",
    assignAiMapToCompany: "/MapAiPackage/AssignAiMapToCompanyUser",
    getAllAiPackage: "/MapAiPackage/GetAllAdminPanelAiPackage",
    getAiPackageById: "/MapAiPackage/getAiPackage",
    updatePackageApiURL: "/MapAiPackage/UpdateMapAiPackage",
    getAllCouponUrl: "/Coupon/GetCoupons",
    createCouponUrl: "/Coupon/CreateCoupon",
    updateCouponUrl: "/Coupon/UpdateCoupon",
    deleteCouponUrl: "/Coupon/DeleteCoupon",
    searchUserByName: "/Coupon/SearchUserByName",
    getCouponById: "/Coupon/GetCouponByIdAsync",
    changeStatusCoupon: "/Coupon/ChangeCouponStatus",
    forgotPasswordUrl: "/Account/ResetPasswordTokenMail",
    uploadDocument: "/Document/UploadDocument",
    getDocument: "/Document/GetCompanyDocuments",
    deleteDocument: "/Document/DeleteDocument",
    getRemainingDataSpaceById: "/Document/GetRemainingSizeByCompanyId",
  },

  local: {
    appBaseEnvURL: "",
    apiBaseEnvURL: "https://foramind-api-dev.azurewebsites.net/api",
  },

  dev: {
    appBaseEnvURL: "",
    apiBaseEnvURL: "https://foramind-api-dev.azurewebsites.net/api",
  },

  test: {
    // appBaseEnvURL: "",
    appBaseEnvURL: "/admin",
    apiBaseEnvURL: "https://foramind-api-test.azurewebsites.net/api",
  },

  production: {
    appBaseEnvURL: "/admin",
    apiBaseEnvURL: "https://foramind-api.azurewebsites.net/api",
  },
};

export default Resources;
