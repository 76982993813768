import { modalConstants } from '../constants';

export const modalActions = {
  success,
  error,
  info,
  warning,
  clear
};

function success(message) {
  return { type: modalConstants.SUCCESS, message };
}

function error(message) {
  return { type: modalConstants.ERROR, message };
}

function info(message) {
  return { type: modalConstants.INFO, message };
}

function warning(message) {
  return { type: modalConstants.WARNING, message };
}

function clear() {
  return { type: modalConstants.CLEAR };
}
