import { corporationConstants } from "../constants";
import { corporationService } from "../services";
import { modalActions } from "./";
import Languages from "../libraries/languages";
import Resources from "../libraries/resources";

export const corporationActions = {
  getAllCorporations,
  getCorporationById,
  getCorporationDetailById,
  addCorporation,
  updateCorporation,
  updateCorporationInfos,
  makeCorporationActivePassive,
  getAllCorporationApplyList,
  getCorporationApplyById,
  updateCorporationApplyInfos,
  addCorporationSubscription,
  updateCompanySubscriptionModel,
  getCorporationSubscriptionInfos,
  getCompanyAdmins,
  deleteCompanyAdmin,
  addCompanyAdmin
};

function getAllCorporations() {
  return (dispatch) => {
    dispatch(request());

    corporationService.getAllCorporations().then(
      (corporations) => {
        dispatch(success(corporations));
      },
      (error) => {
        // dispatch(modalActions.error(''));
        // dispatch(modalActions.error(Languages.getValue(error)));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: corporationConstants.GETALL_CORPORATIONS_REQUEST };
  }
  function success(corporations) {
    return {
      type: corporationConstants.GETALL_CORPORATIONS_SUCCESS,
      corporations,
    };
  }
  function failure(error) {
    return { type: corporationConstants.GETALL_CORPORATIONS_FAILURE, error };
  }
}

// sol listeden kurum secince
function getCorporationById(id) {
  return (dispatch) => {
    dispatch(request());

    corporationService.getCorporationById(id).then(
      (corporationInfo) => {
        dispatch(success(corporationInfo));
        localStorage.setItem(
          "selectedCorporation",
          JSON.stringify(corporationInfo.company)
        );
        window.location.reload();

        // const locationHref = window.location.href.split("#")[1].split("/");
        // if(locationHref[1].indexOf('?') > -1) {
        //   window.location.reload();
        // } else {
        //   if (locationHref[1] === 'users' && locationHref[2] !== "") {
        //     window.location.href = "/#/users";
        //     window.location.reload();
        //   }
        // }
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(modalActions.error(Languages.getValue("errorOccuredMsgTxt")));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: corporationConstants.GET_CORPORATION_REQUEST };
  }
  function success(selectedCorporation) {
    return {
      type: corporationConstants.GET_CORPORATION_SUCCESS,
      selectedCorporation,
    };
  }
  function failure(error) {
    return { type: corporationConstants.GET_CORPORATION_FAILURE, error };
  }
}

// listedeki kurumlardan detaya gidince
function getCorporationDetailById(id) {
  return (dispatch) => {
    dispatch(request());

    corporationService.getCorporationById(id).then(
      (corporationInfo) => {
        dispatch(success(corporationInfo));
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(modalActions.error(error));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: corporationConstants.GET_CORPORATION_DETAIL_REQUEST };
  }
  function success(detailedCorporation) {
    return {
      type: corporationConstants.GET_CORPORATION_DETAIL_SUCCESS,
      detailedCorporation,
    };
  }
  function failure(error) {
    return { type: corporationConstants.GET_CORPORATION_DETAIL_FAILURE, error };
  }
}

function addCorporation(addedCorporation) {
  return (dispatch) => {
    dispatch(request(addedCorporation));

    corporationService.addCorporation(addedCorporation).then(
      (addedCorporation) => {
        dispatch(modalActions.success(""));
        dispatch(success(addedCorporation));
        dispatch(
          modalActions.success(Languages.getValue("addedActionSuccessMsgTxt"))
        );
        window.location.href =
          Resources.getValue("appBaseEnvURL") + "/#/corporations";
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(failure(error.toString()));
        dispatch(modalActions.error(error));
      }
    );
  };

  function request(addedCorporation) {
    return {
      type: corporationConstants.CORPORATION_ADD_REQUEST,
      addedCorporation,
    };
  }
  function success(addedCorporation) {
    return {
      type: corporationConstants.CORPORATION_ADD_SUCCESS,
      addedCorporation,
    };
  }
  function failure(error) {
    return { type: corporationConstants.CORPORATION_ADD_FAILURE, error };
  }
}

// super admin kurumu guncellerken
function updateCorporation(company) {
  return (dispatch) => {
    dispatch(request());

    corporationService.updateCorporation(company).then(
      (company) => {
        dispatch(modalActions.success(""));
        dispatch(success(company));
        dispatch(
          modalActions.success(Languages.getValue("updateActionSuccessMsgTxt"))
        );
        // window.location.reload();
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(modalActions.error(Languages.getValue("errorOccuredMsgTxt")));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: corporationConstants.CORPORATION_UPDATE_REQUEST };
  }
  function success(updatedCorporation) {
    return {
      type: corporationConstants.CORPORATION_UPDATE_SUCCESS,
      updatedCorporation,
    };
  }
  function failure(error) {
    return { type: corporationConstants.CORPORATION_UPDATE_FAILURE, error };
  }
}


// login olan kullanici kurumunu guncellerken
function updateCorporationInfos(company) {
  return (dispatch) => {
    dispatch(request());

    corporationService.updateCorporationInfos(company).then(
      (company) => {
        dispatch(modalActions.success(""));
        dispatch(success(company));
        dispatch(
          modalActions.success(Languages.getValue("updateActionSuccessMsgTxt"))
        );
        window.location.reload();
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(modalActions.error(Languages.getValue("errorOccuredMsgTxt")));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: corporationConstants.CORPORATION_UPDATE_INFOS_REQUEST };
  }
  function success(updatedInfos) {
    return {
      type: corporationConstants.CORPORATION_UPDATE_INFOS_SUCCESS,
      updatedInfos,
    };
  }
  function failure(error) {
    return {
      type: corporationConstants.CORPORATION_UPDATE_INFOS_FAILURE,
      error,
    };
  }
}

function makeCorporationActivePassive(id, isActive) {
  return (dispatch) => {
    dispatch(request());

    corporationService.makeCorporationActivePassive(id, isActive).then(
      () => {
        dispatch(modalActions.success(""));
        dispatch(success());
        dispatch(
          modalActions.success(
            Languages.getValue("corporationStatusUpdatedSuccessMsgTxt")
          )
        );
        dispatch(getAllCorporations());
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(modalActions.error(error));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: corporationConstants.ACTIVE_PASSIVE_COMPANY_REQUEST };
  }
  function success() {
    return { type: corporationConstants.ACTIVE_PASSIVE_COMPANY_SUCCESS };
  }
  function failure(error) {
    return { type: corporationConstants.ACTIVE_PASSIVE_COMPANY_FAILURE, error };
  }
}

function getAllCorporationApplyList() {
  return (dispatch) => {
    dispatch(request());

    corporationService.getAllCorporationApplyList().then(
      (applyList) => {
        dispatch(success(applyList));
      },
      (error) => {
        // dispatch(modalActions.error(''));
        // dispatch(modalActions.error(Languages.getValue(error)));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: corporationConstants.GET_CORPORATION_APPLY_LIST_REQUEST };
  }
  function success(applyList) {
    return {
      type: corporationConstants.GET_CORPORATION_APPLY_LIST_SUCCESS,
      applyList,
    };
  }
  function failure(error) {
    return {
      type: corporationConstants.GET_CORPORATION_APPLY_LIST_FAILURE,
      error,
    };
  }
}

// listedeki kurum basvurularindan detaya gidince
function getCorporationApplyById(id) {
  return (dispatch) => {
    dispatch(request());

    corporationService.getCorporationApplyById(id).then(
      (applyInfo) => {
        dispatch(success(applyInfo));
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(modalActions.error(error));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: corporationConstants.GET_CORPORATION_APPLY_DETAIL_REQUEST };
  }
  function success(detailedCorporationApply) {
    return {
      type: corporationConstants.GET_CORPORATION_APPLY_DETAIL_SUCCESS,
      detailedCorporationApply,
    };
  }
  function failure(error) {
    return {
      type: corporationConstants.GET_CORPORATION_APPLY_DETAIL_FAILURE,
      error,
    };
  }
}

// kurum basvurusunu guncelleme
function updateCorporationApplyInfos(applyInfo) {
  return (dispatch) => {
    dispatch(request());

    corporationService.updateCorporationApplyInfos(applyInfo).then(
      (applyInfo) => {
        dispatch(modalActions.success(""));
        dispatch(success(applyInfo));
        dispatch(
          modalActions.success(Languages.getValue("updateActionSuccessMsgTxt"))
        );
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(modalActions.error(Languages.getValue("errorOccuredMsgTxt")));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: corporationConstants.CORPORATION_APPLY_UPDATE_REQUEST };
  }
  function success(updatedCorporationApply) {
    return {
      type: corporationConstants.CORPORATION_APPLY_UPDATE_SUCCESS,
      updatedCorporationApply,
    };
  }
  function failure(error) {
    return {
      type: corporationConstants.CORPORATION_APPLY_UPDATE_FAILURE,
      error,
    };
  }
}

// kurum icin abonelik baslatma
function addCorporationSubscription(addedCorporationSubscription) {
  return (dispatch) => {
    dispatch(request(addedCorporationSubscription));

    corporationService
      .addCorporationSubscription(addedCorporationSubscription)
      .then(
        (addedCorporationSubscription) => {
          dispatch(modalActions.success(""));
          dispatch(success(addedCorporationSubscription));
          dispatch(
            modalActions.success(Languages.getValue("addedActionSuccessMsgTxt"))
          );
          window.location.href =
            Resources.getValue("appBaseEnvURL") + "/#/corporations";
        },
        (error) => {
          dispatch(modalActions.error(""));
          dispatch(failure(error.toString()));
          dispatch(modalActions.error(error));
        }
      );
  };

  function request(addedCorporationSubscription) {
    return {
      type: corporationConstants.CORPORATION_SUBSCRIPTION_ADD_REQUEST,
      addedCorporationSubscription,
    };
  }
  function success(addedCorporationSubscription) {
    return {
      type: corporationConstants.CORPORATION_SUBSCRIPTION_ADD_SUCCESS,
      addedCorporationSubscription,
    };
  }
  function failure(error) {
    return {
      type: corporationConstants.CORPORATION_SUBSCRIPTION_ADD_FAILURE,
      error,
    };
  }
}

//kurum abonelik update
function updateCompanySubscriptionModel(updatedCorporationSubscription) {
  return (dispatch) => {
    dispatch(request(updatedCorporationSubscription));

    corporationService
      .updateCompanySubscriptionModel(updatedCorporationSubscription)
      .then(
        (updatedCorporationSubscription) => {
          dispatch(modalActions.success(""));
          dispatch(success(updatedCorporationSubscription));
          dispatch(
            modalActions.success(
              Languages.getValue("updateActionSuccessMsgTxt")
            )
          );
          window.location.href =
            Resources.getValue("appBaseEnvURL") + "/#/corporations";
        },
        (error) => {
          dispatch(modalActions.error(""));
          dispatch(failure(error.toString()));
          dispatch(modalActions.error(error));
        }
      );
  };

  function request(updatedCorporationSubscription) {
    return {
      type: corporationConstants.CORPORATION_SUBSCRIPTION_UPDATE_REQUEST,
      updatedCorporationSubscription,
    };
  }
  function success(updatedCorporationSubscription) {
    return {
      type: corporationConstants.CORPORATION_SUBSCRIPTION_UPDATE_SUCCESS,
      updatedCorporationSubscription,
    };
  }
  function failure(error) {
    return {
      type: corporationConstants.CORPORATION_SUBSCRIPTION_UPDATE_FAILURE,
      error,
    };
  }
}

function getCorporationSubscriptionInfos() {
  return (dispatch) => {
    dispatch(request());

    corporationService.getCorporationSubscriptionInfos().then(
      (subscriptionInfo) => {
        console.log(subscriptionInfo);
        dispatch(success(subscriptionInfo));
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(modalActions.error(error));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return {
      type: corporationConstants.GET_CORPORATION_SUBSCRIPTION_INFO_REQUEST,
    };
  }
  function success(corporationSubscriptionInformations) {
    return {
      type: corporationConstants.GET_CORPORATION_SUBSCRIPTION_INFO_SUCCESS,
      corporationSubscriptionInformations,
    };
  }
  function failure(error) {
    return {
      type: corporationConstants.GET_CORPORATION_SUBSCRIPTION_INFO_FAILURE,
      error,
    };
  }
}


function getCompanyAdmins(companyId) {
  return (dispatch) => {
    dispatch(request());

    corporationService.getCompanyAdmins(companyId).then(
      (applyInfo) => {
        dispatch(success(applyInfo));
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(modalActions.error(error));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: corporationConstants.GET_CORPORATION_ADMINS_REQUEST };
  }
  function success(companyAdmins) {
    return {
      type: corporationConstants.GET_CORPORATION_ADMINS_SUCCESS,
      companyAdmins,
    };
  }
  function failure(error) {
    return {
      type: corporationConstants.GET_CORPORATION_ADMINS_FAILURE,
      error,
    };
  }
}


function deleteCompanyAdmin(userId) {
  return (dispatch) => {
    dispatch(request());

    corporationService.deleteCompanyAdmin(userId).then(
      (corporationInfo) => {
        dispatch(success(corporationInfo));
        dispatch(
          modalActions.success(Languages.getValue("deletedActionSuccessMsgTxt"))
        );
        window.location.reload();
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(modalActions.error(error));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: corporationConstants.DELETE_COMPANY_ADMIN_REQUEST };
  }
  function success(detailedCorporation) {
    return {
      type: corporationConstants.DELETE_COMPANY_ADMIN_SUCCESS,
      detailedCorporation,
    };
  }
  function failure(error) {
    return { type: corporationConstants.DELETE_COMPANY_ADMIN_FAILURE, error };
  }
}

function addCompanyAdmin(data) {
  return (dispatch) => {
    dispatch(request(data));

    corporationService.addCompanyAdmin(data).then(
      (data) => {
        dispatch(success(data));
        dispatch(
          modalActions.success(Languages.getValue("addedActionSuccessMsgTxt"))
        );
        window.location.reload();
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(modalActions.error(error));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: corporationConstants.ADD_COMPANY_ADMIN_REQUEST };
  }
  function success(detailedCorporation) {
    return {
      type: corporationConstants.ADD_COMPANY_ADMIN_SUCCESS,
      detailedCorporation,
    };
  }
  function failure(error) {
    return { type: corporationConstants.ADD_COMPANY_ADMIN_FAILURE, error };
  }
}
