import { authHeader } from "../helpers";
import { userService } from "./index";
import Resources from "../libraries/resources";

const apiBaseURL = Resources.getValue("apiBaseEnvURL");

export const chatGptService = {
  getAllPackages,
  getPackageById,
  updatePackage,
  addPackage,
  assignPackageToCompany,
  assignPackageToUser,
};


function getAllPackages() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("getAllAiPackage")}`,
    requestOptions
  ).then(handleResponse);
}

function getPackageById(packageId) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("getAiPackageById"
    )}?packageId=${packageId}`,
    requestOptions
  ).then(handleResponse);
}

function updatePackage(pack) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(pack),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("updatePackageApiURL")}`,
    requestOptions
  ).then(handleResponse);
}

function addPackage(pack) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(pack),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("createMapAiPackage")}`,
    requestOptions
  ).then(handleResponse);
}

function assignPackageToUser(userEmail, mapPackageId) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };
  return fetch(
    `${apiBaseURL}${Resources.getValue(
      "assignAiMapToUser"
    )}?email=${userEmail}&mapPackageId=${mapPackageId}`,
    requestOptions
  ).then(handleResponse);

}

function assignPackageToCompany(pack) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(pack),
  };
  return fetch(
    `${apiBaseURL}${Resources.getValue("assignAiMapToCompany")}`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
      }

      const error =
        (data && data.message) ||
        (data && data.errors && data.errors[0]) ||
        response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
