import { couponConstants } from "../constants";
import { couponService } from "../services";
import { modalActions } from "./";
import Languages from "../libraries/languages";
import Resources from "../libraries/resources";

export const couponActions = {
  getAllCoupons,
  getCouponById,
  createCoupon,
  updateCoupon,
  searchUserByName,
  deleteCoupon,
  changeCouponStatus,
};

function getAllCoupons() {
  return (dispatch) => {
    dispatch(request());

    couponService.getAllCoupons().then(
      (coupons) => {
        dispatch(success(coupons));
      },
      (error) => {
        // dispatch(modalActions.error(''));
        // dispatch(modalActions.error(Languages.getValue(error)));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: couponConstants.GET_ALL_COUPON_REQUEST };
  }
  function success(coupons) {
    return {
      type: couponConstants.GET_ALL_COUPON_SUCCESS,
      coupons,
    };
  }
  function failure(error) {
    return { type: couponConstants.GET_ALL_COUPON_FAILURE, error };
  }
}
function getCouponById(id) {
  return (dispatch) => {
    dispatch(request());

    couponService.getCouponById(id).then(
      (coupon) => {
        dispatch(success(coupon));
      },
      (error) => {
        // dispatch(modalActions.error(''));
        // dispatch(modalActions.error(Languages.getValue(error)));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: couponConstants.GET_COUPON_BY_ID_REQUEST };
  }
  function success(coupon) {
    return {
      type: couponConstants.GET_COUPON_BY_ID_SUCCESS,
      coupon,
    };
  }
  function failure(error) {
    return { type: couponConstants.GET_COUPON_BY_ID_FAILURE, error };
  }
}

function createCoupon(createdCoupon) {
  return (dispatch) => {
    dispatch(request(createdCoupon));

    couponService.createCoupon(createdCoupon).then(
      (createdCoupon) => {
        dispatch(modalActions.success(""));
        dispatch(success(createdCoupon));
        dispatch(
          modalActions.success(Languages.getValue("addedActionSuccessMsgTxt"))
        );
        window.location.href =
          Resources.getValue("appBaseEnvURL") + "/#/coupons";
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(failure(error.toString()));
        dispatch(modalActions.error(error));
      }
    );
  };

  function request(createdCoupon) {
    return {
      type: couponConstants.ADD_COUPON_REQUEST,
      createdCoupon,
    };
  }
  function success(createdCoupon) {
    return {
      type: couponConstants.ADD_COUPON_SUCCESS,
      createdCoupon,
    };
  }
  function failure(error) {
    return { type: couponConstants.ADD_COUPON_FAILURE, error };
  }
}

function updateCoupon(updatedCoupon) {
  return (dispatch) => {
    dispatch(request(updatedCoupon));

    couponService.updateCoupon(updatedCoupon).then(
      (updatedCoupon) => {
        dispatch(modalActions.success(""));
        dispatch(success(updatedCoupon));
        dispatch(
          modalActions.success(Languages.getValue("addedActionSuccessMsgTxt"))
        );
        window.location.href =
          Resources.getValue("appBaseEnvURL") + "/#/coupons";
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(failure(error.toString()));
        dispatch(modalActions.error(error));
      }
    );
  };

  function request(updatedCoupon) {
    return {
      type: couponConstants.UPDATE_COUPON_REQUEST,
      updatedCoupon,
    };
  }
  function success(updatedCoupon) {
    return {
      type: couponConstants.UPDATE_COUPON_SUCCESS,
      updatedCoupon,
    };
  }
  function failure(error) {
    return { type: couponConstants.UPDATE_COUPON_FAILURE, error };
  }
}

function changeCouponStatus(couponId, changeValue) {
  return (dispatch) => {
    dispatch(request());

    couponService.changeCouponStatus(couponId, changeValue).then(
      () => {
        dispatch(modalActions.success(""));
        dispatch(success());
        dispatch(
          modalActions.success(Languages.getValue("changeCouponMsgTxt"))
        );
        dispatch(getAllCoupons());
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(modalActions.error(error));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: couponConstants.CHANGE_COUPON_REQUEST };
  }
  function success() {
    return { type: couponConstants.CHANGE_COUPON_SUCCESS };
  }
  function failure(error) {
    return { type: couponConstants.CHANGE_COUPON_FAILURE, error };
  }
}

function deleteCoupon(couponId) {
  return (dispatch) => {
    dispatch(request());

    couponService.deleteCoupon(couponId).then(
      () => {
        dispatch(modalActions.success(""));
        dispatch(success());
        dispatch(
          modalActions.success(Languages.getValue("couponDeletedMsgTxt"))
        );
        dispatch(getAllCoupons());
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(modalActions.error(error));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: couponConstants.DELETE_COUPON_REQUEST };
  }
  function success() {
    return { type: couponConstants.DELETE_COUPON_SUCCESS };
  }
  function failure(error) {
    return { type: couponConstants.DELETE_COUPON_FAILURE, error };
  }
}

function searchUserByName(name) {
  return (dispatch) => {
    dispatch(request());

    couponService.searchUserByName(name).then(
      (name) => {
        dispatch(success(name));
      },
      (error) => {
        // dispatch(modalActions.error(''));
        // dispatch(modalActions.error(Languages.getValue(error)));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: couponConstants.GET_USER_BY_NAME_REQUEST };
  }
  function success(name) {
    return {
      type: couponConstants.GET_USER_BY_NAME_SUCCESS,
      name,
    };
  }
  function failure(error) {
    return { type: couponConstants.GET_USER_BY_NAME_FAILURE, error };
  }
}
