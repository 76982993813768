import { chatGptConstants } from "../constants";

const initialState = {
  items: [],
  packageById: {},
  error: "",
};

export function packages(state = initialState, action) {
  switch (action.type) {
    case chatGptConstants.GETALL_PACKAGES_REQUEST:
      return {
        ...state,
        loading: true,
        packageById: {},
      };
    case chatGptConstants.GETALL_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        packageById: {},
        error: "",
        items: action.packages,
      };
    case chatGptConstants.GETALL_PACKAGES_FAILURE:
      return {
        ...state,
        loading: false,
        items: [],
        packageById: {},
        error: action.error,
      };

      case chatGptConstants.GET_PACKAGE_DETAIL_REQUEST:
        return {
          ...state,
          loading: true,
          packageById: {},
          error: "",
        };
      case chatGptConstants.GET_PACKAGE_DETAIL_SUCCESS:
        return {
          ...state,
          loading: false,
          packageById: action.packageById,
          error: "",
        };
      case chatGptConstants.GET_PACKAGE_DETAIL_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
          packageById: {},
        };
        case chatGptConstants.PACKAGE_UPDATE_REQUEST:
          return {
            ...state,
            loading: true,
            error: "",
          };
        case chatGptConstants.PACKAGE_UPDATE_SUCCESS:
          return {
            ...state,
            loading: false,
            error: "",
            detailedFee: action.updatedPackage.data,
          };
        case chatGptConstants.PACKAGE_UPDATE_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error,
          };

          case chatGptConstants.PACKAGE_ADD_REQUEST:
            return {
              ...state,
              loading: true,
              error: "",
            };
          case chatGptConstants.PACKAGE_UPDATE_SUCCESS:
            return {
              ...state,
              loading: false,
              error: "",
            };
          case chatGptConstants.PACKAGE_ADD_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error,
            };
      
    default:
      return state;
  }
}
