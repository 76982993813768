import { videoService } from "../services/video";
import { modalActions } from "./";
import Languages from "../libraries/languages";
import Resources from "../libraries/resources";
import { videoConstants } from "../constants/video";

export const videoActions = {
  addVideo,
  getAllVideo,
  getVideoDetailById,
  updateVideo,
  makeVideoActivePassive
};

function makeVideoActivePassive(id, isActive) {
  return dispatch => {
    dispatch(request());

    videoService.makeVideoActivePassive(id, isActive)
      .then(
        () => {
          dispatch(modalActions.success(''));
          dispatch(success());
          dispatch(modalActions.success(Languages.getValue('videoStatusUpdatedSuccessMsgTxt')));
          dispatch(getAllVideo());
        },
        error => {
          dispatch(modalActions.error(''));
          dispatch(modalActions.error(error));
          dispatch(failure(error.toString()))
        }
      );
  };

  function request() { return { type: videoConstants.ACTIVE_PASSIVE_VIDEO_REQUEST } }
  function success() { return { type: videoConstants.ACTIVE_PASSIVE_VIDEO_SUCCESS } }
  function failure(error) { return { type: videoConstants.ACTIVE_PASSIVE_VIDEO_FAILURE, error } }
}

function updateVideo(video) {
  return dispatch => {
    dispatch(request());

    videoService.updateVideo(video)
      .then(
        video => {
          dispatch(modalActions.success(''));
          dispatch(success(video));
          dispatch(modalActions.success(Languages.getValue('updateActionSuccessMsgTxt')));
        },
        error => {
          dispatch(modalActions.error(''));
          dispatch(modalActions.error(error ? error.toString() : Languages.getValue('updateActionSuccessMsgTxt')));
          dispatch(failure(error.toString()));
        }
      );
  };

  function request() { return { type: videoConstants.VIDEO_UPDATE_REQUEST } }
  function success(updatedVideo) { return { type: videoConstants.VIDEO_UPDATE_SUCCESS, updatedVideo } }
  function failure(error) { return { type: videoConstants.VIDEO_UPDATE_FAILURE, error } }
}

function getVideoDetailById(id) {
  return dispatch => {
    dispatch(request());

    videoService.getVideoById(id)
      .then(
        (videoInfo) => {
          dispatch(success(videoInfo.data));
        },
        error => {
          dispatch(modalActions.error(''));
          dispatch(modalActions.error(error));
          dispatch(failure(error.toString()))
        }
      );
  };

  function request() { return { type: videoConstants.GET_VIDEO_DETAIL_REQUEST } }
  function success(detailedVideo) { return { type: videoConstants.GET_VIDEO_DETAIL_SUCCESS, detailedVideo } }
  function failure(error) { return { type: videoConstants.GET_VIDEO_DETAIL_FAILURE, error } }
}

function getAllVideo() {
  return dispatch => {
    dispatch(request());

    videoService.getVideo()
      .then(
        video => {
          dispatch(success(video.videoInfoModel));
        },
        error => {
          // dispatch(modalActions.error(''));
          // dispatch(modalActions.error(Languages.getValue(error)));
          dispatch(failure(error.toString()))
        }
      );
  };

  function request() { return { type: videoConstants.GETALL_VIDEO_REQUEST } }
  function success(video) { return { type: videoConstants.GETALL_VIDEO_SUCCESS, video } }
  function failure(error) { return { type: videoConstants.GETALL_VIDEO_FAILURE, error } }
}

function addVideo(addedVideo) {
  return (dispatch) => {
    dispatch(request(addedVideo));

    videoService.CreateVideoInfo(addedVideo).then(
      (addedVideo) => {
        dispatch(modalActions.success(""));
        dispatch(success(addedVideo));
        dispatch(
          modalActions.success(Languages.getValue("addedActionSuccessMsgTxt"))
        );
        window.location.href = Resources.getValue("appBaseEnvURL") + "/#/videos";
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(modalActions.error(error));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(addedVideo) {
    return { type: videoConstants.VIDEO_ADD_REQUEST, addedVideo };
  }
  function success(addedVideo) {
    return { type: videoConstants.VIDEO_ADD_SUCCESS, addedVideo };
  }
  function failure(error) {
    return { type: videoConstants.VIDEO_ADD_FAILURE, error };
  }
}
