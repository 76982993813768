import React, { useEffect } from 'react'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CRow
} from '@coreui/react';
import LoginForm from '../../../components/loginForm';
import LanguageDropdown from '../../../containers/TheHeaderDropdownLanguage';
import Resources from '../../../libraries/resources';

const Login = () => {

  useEffect(() => {
    // const token = localStorage.getItem('adminToken');
    // const refreshToken = localStorage.getItem('adminRefreshToken');
    // if(token && refreshToken) {
    //   window.location.href = Resources.getValue('appBaseEnvURL') + '/dashoboard';
    // }

    // token expired control
    const token = localStorage.getItem('adminToken');
    if(token) {
      const tokenJSON = JSON.parse(atob(token.split('.')[1]));
      if (tokenJSON.exp < new Date().getTime() / 1000) { // ms cinsinden
        console.log("EXPIRED");
        localStorage.removeItem('adminUserInfo');
        localStorage.removeItem('adminRefreshToken');
        localStorage.removeItem('adminToken');
        localStorage.removeItem("selectedCorporation");
        return false;
      } else {
        window.location.href = Resources.getValue('appBaseEnvURL') + '/dashoboard';
      }
    }
  });

  return (
    <div className="c-app c-default-layout login-page flex-row align-items-center">
      <LanguageDropdown />
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <LoginForm />
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
