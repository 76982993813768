import React from "react";
import Languages from "./libraries/languages";

const Dashboard = React.lazy(() => import("./views/pages/dashboard/Dashboard"));
// Users
const Users = React.lazy(() => import("./views/pages/users/Users"));
// const User = React.lazy(() => import('./views/pages/users/User'));
const UserUpdate = React.lazy(() => import("./views/pages/users/UserUpdate"));
const UserAdd = React.lazy(() => import("./views/pages/users/UserAdd"));
const UserTime = React.lazy(() =>
  import("./views/pages/users/UserTimeExtension")
);
// Corporations
const Corporations = React.lazy(() =>
  import("./views/pages/corporations/Corporations")
);
const CorporationDocumentList = React.lazy(() =>
  import("./views/pages/corporations/CorporationDocuments")
);
const CorporationDocumentAdd = React.lazy(() =>
  import("./views/pages/corporations/CorporationDocumentAdd")
);

// const Corporation = React.lazy(() => import('./views/pages/corporations/Corporation'));
const CorporationUpdate = React.lazy(() =>
  import("./views/pages/corporations/CorporationUpdate")
);
const AdminCorporationUpdate = React.lazy(() =>
  import("./views/pages/corporations/AdminCorporationUpdate")
);
const CorporationAdd = React.lazy(() =>
  import("./views/pages/corporations/CorporationAdd")
);
const CorporationSubscriptionDetails = React.lazy(() =>
  import("./views/pages/corporations/CorporationSubscriptionDetails")
);
// Feedbacks
const Feedbacks = React.lazy(() => import("./views/pages/feedbacks/Feedbacks"));
const Feedback = React.lazy(() => import("./views/pages/feedbacks/Feedback"));
// Blog Posts
const BlogPosts = React.lazy(() => import("./views/pages/blogs/BlogPosts"));
const BlogPostUpdate = React.lazy(() =>
  import("./views/pages/blogs/BlogPostUpdate")
);
const BlogPostAdd = React.lazy(() => import("./views/pages/blogs/BlogPostAdd"));
// Profile
const Profile = React.lazy(() => import("./views/pages/profile/Profile"));
// New Video
const AddVideo = React.lazy(() => import("./views/pages/video/VideoAdd"));
//Video List
const VideosList = React.lazy(() => import("./views/pages/video/VideosList"));
//Video Update
const VideoUpdate = React.lazy(() => import("./views/pages/video/VideoUpdate"));
//Fee List
const FeeList = React.lazy(() => import("./views/pages/fee/FeeList"));
//Fee Update
const FeeUpdate = React.lazy(() => import("./views/pages/fee/FeeUpdate"));
// Change password
const ChangePassword = React.lazy(() =>
  import("./views/pages/change-password/ChangePassword")
);
//Coupon
const Coupon = React.lazy(() => import("./views/pages/coupon/Coupon"));
const Coupons = React.lazy(() => import("./views/pages/coupon/CouponList"));
const CouponUpdate = React.lazy(() =>
  import("./views/pages/coupon/CouponUpdate")
);

const ChatGpt = React.lazy(() => import("./views/pages/chat-gpt/PackageList"));

const AssignChatGpt = React.lazy(() =>
  import("./views/pages/chat-gpt/AssignAiMap")
);

const PackageUpdate = React.lazy(() =>
  import("./views/pages/chat-gpt/PackageUpdate")
);

const PackageCreate = React.lazy(() =>
  import("./views/pages/chat-gpt/PackageCreate")
);

const routes = [
  {
    path: "/",
    exact: true,
    name: Languages.getValue("homeMsgTxt"),
    component: Dashboard,
  },
  {
    path: "/dashboard",
    name: Languages.getValue("homepageMsgTxt"),
    component: Dashboard,
  },
  {
    path: "/users",
    exact: true,
    name: Languages.getValue("usersMsgTxt"),
    component: Users,
  },
  {
    path: "/users/:id",
    exact: true,
    name: Languages.getValue("userDetailMsgTxt"),
    component: UserUpdate,
    // component: User
  },
  {
    path: "/user-detail",
    exact: true,
    name: Languages.getValue("updateUserMsgTxt"),
    component: UserUpdate,
  },
  {
    path: "/user/add",
    exact: true,
    name: Languages.getValue("userAddMsgTxt"),
    component: UserAdd,
  },
  {
    path: "/user/time",
    exact: true,
    name: Languages.getValue("userTimeExtensionmsgTxt"),
    component: UserTime,
  },
  {
    path: "/corporations",
    exact: true,
    name: Languages.getValue("corporationsMsgTxt"),
    component: Corporations,
  },
  {
    path: "/corporation/:id",
    exact: true,
    name: Languages.getValue("corporationDetailMsgTxt"),
    component: CorporationUpdate,
  },
  {
    path: "/corporation-update",
    exact: true,
    name: Languages.getValue("corporationUpdateMsgTxt"),
    component: AdminCorporationUpdate,
  },
  {
    path: "/corporation-detail",
    exact: true,
    name: Languages.getValue("updateCorporationMsgTxt"),
    component: CorporationUpdate,
  },
  {
    path: "/corporations/add",
    exact: true,
    name: Languages.getValue("corporationAddMsgTxt"),
    component: CorporationAdd,
  },
  {
    path: "/document",
    exact: true,
    name: Languages.getValue("corporationDocumentMsgTxt"),
    component: CorporationDocumentList,
  },
  {
    path: "/document/add",
    exact: true,
    name: Languages.getValue("documentAdd"),
    component: CorporationDocumentAdd,
  },
  {
    path: "/subscription-details", // to get info about their company subscription for company admin users
    exact: true,
    name: Languages.getValue("subscriptionDetailsMsgTxt"),
    component: CorporationSubscriptionDetails,
  },
  {
    path: "/feedbacks",
    exact: true,
    name: Languages.getValue("feedbacksMsgTxt"),
    component: Feedbacks,
  },
  {
    path: "/feedback/:id",
    exact: true,
    name: Languages.getValue("feedbackDetailMsgTxt"),
    component: Feedback,
  },
  {
    path: "/feedback-detail",
    exact: true,
    name: Languages.getValue("feedbackDetailMsgTxt"),
    component: Feedback,
  },
  {
    path: "/blogs",
    exact: true,
    name: Languages.getValue("blogsMsgTxt"),
    component: BlogPosts,
  },
  {
    path: "/blog/:id",
    exact: true,
    name: Languages.getValue("blogDetailMsgTxt"),
    component: BlogPostUpdate,
  },
  {
    path: "/blogs/add",
    exact: true,
    name: Languages.getValue("blogAddMsgTxt"),
    component: BlogPostAdd,
  },
  {
    path: "/profile",
    exact: true,
    name: Languages.getValue("profileMsgTxt"),
    component: Profile,
  },
  {
    path: "/change-password",
    exact: true,
    name: Languages.getValue("changePasswordMsgTxt"),
    component: ChangePassword,
  },
  {
    path: "/video/add",
    exact: true,
    name: Languages.getValue("newVideoMsgTxt"),
    component: AddVideo,
  },
  {
    path: "/videos",
    exact: true,
    name: Languages.getValue("newVideoMsgTxt"),
    component: VideosList,
  },
  {
    path: "/video/:id",
    exact: true,
    name: Languages.getValue("blogDetailMsgTxt"),
    component: VideoUpdate,
  },
  {
    path: "/fee",
    exact: true,
    name: Languages.getValue("feeListMsgTxt"),
    component: FeeList,
  },
  {
    path: "/packages",
    exact: true,
    name: Languages.getValue("chatGptPackages"),
    component: ChatGpt,
  },
  {
    path: "/package/assign",
    exact: true,
    name: Languages.getValue("chatGptPackages"),
    component: AssignChatGpt,
  },
  {
    path: "/fee/:id",
    exact: true,
    name: Languages.getValue("feeUpdateMsgTxt"),
    component: FeeUpdate,
  },
  {
    path: "/package/:id",
    exact: true,
    name: Languages.getValue("packageUpdateMsgTxt"),
    component: PackageUpdate,
  },
  {
    path: "/add/package",
    exact: true,
    name: Languages.getValue("chatGptPackages"),
    component: PackageCreate,
  },

  {
    path: "/coupon",
    exact: true,
    name: Languages.getValue("couponListMsgTxt"),
    component: Coupon,
  },
  {
    path: "/coupons",
    exact: true,
    name: Languages.getValue("couponListMsgTxt"),
    component: Coupons,
  },
  {
    path: "/couponUpdate/:id",
    exact: true,
    name: Languages.getValue("couponUpdateMsgTxt"),
    component: CouponUpdate,
  },
];

export default routes;
