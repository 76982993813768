import { feeService } from "../services/fee";
import { modalActions } from "./";
import Languages from "../libraries/languages";
import Resources from "../libraries/resources";
import { feeConstants } from "../constants/fee";

export const feeActions = {
  getAllFee,
  getFeeDetailById,
  updateFee,
};

function getAllFee() {
  return (dispatch) => {
    dispatch(request());

    feeService.getFee().then(
      (fee) => {
        dispatch(success(fee.data));
      },
      (error) => {
        // dispatch(modalActions.error(''));
        // dispatch(modalActions.error(Languages.getValue(error)));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: feeConstants.GETALL_FEE_REQUEST };
  }
  function success(fee) {
    return { type: feeConstants.GETALL_FEE_SUCCESS, fee };
  }
  function failure(error) {
    return { type: feeConstants.GETALL_FEE_FAILURE, error };
  }
}

function getFeeDetailById(id) {
  return (dispatch) => {
    dispatch(request());
    feeService.getFeeById(id).then(
      (fee) => {
        dispatch(success(fee.data));
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(modalActions.error(error));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: feeConstants.GET_FEE_DETAIL_REQUEST };
  }
  function success(detailedFee) {
    return { type: feeConstants.GET_FEE_DETAIL_SUCCESS, detailedFee };
  }
  function failure(error) {
    return { type: feeConstants.GET_FEE_DETAIL_FAILURE, error };
  }
}

function updateFee(fee) {
  return (dispatch) => {
    dispatch(request());

    feeService.updateFee(fee).then(
      (fee) => {
        dispatch(modalActions.success(""));
        dispatch(success(fee));
        dispatch(
          modalActions.success(Languages.getValue("updateActionSuccessMsgTxt"))
        );
      },
      (error) => {
        dispatch(modalActions.error(""));
        dispatch(
          modalActions.error(
            error
              ? error.toString()
              : Languages.getValue("updateActionSuccessMsgTxt")
          )
        );
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: feeConstants.FEE_UPDATE_REQUEST };
  }
  function success(updatedFee) {
    return { type: feeConstants.FEE_UPDATE_SUCCESS, updatedFee };
  }
  function failure(error) {
    return { type: feeConstants.FEE_UPDATE_FAILURE, error };
  }
}
