import { authHeader } from "../helpers";
import { userService } from "./index";
import Resources from "../libraries/resources";

const apiBaseURL = Resources.getValue("apiBaseEnvURL");

export const couponService = {
  getAllCoupons,
  getCouponById,
  createCoupon,
  updateCoupon,
  deleteCoupon,
  searchUserByName,
  changeCouponStatus,
};

function getAllCoupons() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${apiBaseURL}${Resources.getValue("getAllCouponUrl")}`,
    requestOptions
  ).then(handleResponse);
}

function getCouponById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${apiBaseURL}${Resources.getValue("getCouponById")}?id=${id}`,
    requestOptions
  ).then(handleResponse);
}

function createCoupon(couponData) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(couponData),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("createCouponUrl")}`,
    requestOptions
  ).then(handleResponse);
}

function updateCoupon(couponData) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(couponData),
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("updateCouponUrl")}`,
    requestOptions
  ).then(handleResponse);
}

function deleteCoupon(couponId) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader() },
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue(
      "deleteCouponUrl"
    )}?couponId=${couponId}`,
    requestOptions
  ).then(handleResponse);
}

function changeCouponStatus(couponId, changeValue) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader() },
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue(
      "changeStatusCoupon"
    )}?couponId=${couponId}&isActive=${changeValue}`,
    requestOptions
  ).then(handleResponse);
}

function searchUserByName(name) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue("searchUserByName")}?username=${name}`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
      }

      const error =
        (data && data.message) ||
        (data && data.errors && data.errors[0]) ||
        response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
