import React, { useEffect } from 'react';
import {
  Route,
  // BrowserRouter as Router,
  Switch,
  HashRouter,
  Redirect
} from 'react-router-dom';
import {
  CModal,
  CModalHeader,
  CModalBody
} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import PrivateRoute from './guard/auth';
import './scss/style.scss';

import { modalActions } from './actions';

// Page Components
import Login from './views/pages/login/Login';
import Page404 from './views/pages/page404/Page404';
import Page500 from './views/pages/page500/Page500';
import ForgotPassword from './views/pages/forgot-password/ForgotPassword';
import TheLayout from './containers/TheLayout';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const App = () => {
  const modal = useSelector(state => state.modal);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(modalActions.clear());
  }, [dispatch]);

  return (
    <>
      {modal.message &&
        <CModal
          show={!!modal.message}
          centered={true}
          color={modal.type}
        >
          {modal.type && (
            <CModalHeader closeButton className="modal-header"></CModalHeader>
          )}
          <CModalBody className="text-center">
            {modal.message}
          </CModalBody>
        </CModal>
      }
      {/* <Router basename={Resources.getValue("appBaseEnvURL")}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/404" component={Page404} />
          <Route path="/500" component={Page500} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <PrivateRoute
            path="/"
            component={TheLayout}
          />
        </Switch>
      </Router> */}
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            <Route exact path="/forgot-password" name="Forgot Password" render={props => <ForgotPassword {...props} />} />
            <PrivateRoute
              path="/"
              component={TheLayout}
            />
            <Redirect from="/" to="/dashboard" />
          </Switch>
        </React.Suspense>
      </HashRouter>
    </>
  );
}

export default App;
