import { videoConstants } from "../constants";

const initialState = {
  items: [],
  detailedVideo: {},
  error: "",
  deletedVideo: {},
};

export function video(state = initialState, action) {
  switch (action.type) {
    case videoConstants.GETALL_VIDEO_REQUEST:
      return {
        ...state,
        loading: true,
        detailedVideo: {},
      };
    case videoConstants.GETALL_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        detailedVideo: {},
        error: "",
        items: action.video,
      };
    case videoConstants.GETALL_VIDEO_FAILURE:
      return {
        ...state,
        loading: false,
        items: [],
        detailedVideo: {},
        error: action.error,
      };
    case videoConstants.VIDEO_ADD_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case videoConstants.VIDEO_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case videoConstants.VIDEO_ADD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case videoConstants.GET_VIDEO_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        detailedVideo: {},
        error: "",
      };
    case videoConstants.GET_VIDEO_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        detailedVideo: action.detailedVideo,
        error: "",
      };
    case videoConstants.GET_VIDEO_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        detailedVideo: {},
      };
    case videoConstants.VIDEO_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case videoConstants.VIDEO_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        detailedVideo: action.updatedVideo.data,
      };
    case videoConstants.VIDEO_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };


      case videoConstants.ACTIVE_PASSIVE_VIDEO_REQUEST:
        return {
          ...state,
          loading: true,
          error: ''
        };
      case videoConstants.ACTIVE_PASSIVE_VIDEO_SUCCESS:
        return {
          ...state,
          loading: false,
          error: ''
        };
      case videoConstants.ACTIVE_PASSIVE_VIDEO_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };


    default:
      return state;
  }
}
