import React, { useState } from 'react'
import {
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
} from '@coreui/react'
import Languages from '../libraries/languages';

const Modal = (props) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  }

  const messageType = (type) => {
    switch (type) {
      case "error":
        return Languages.getValue('errorMsgTxt');
      case "info":
        return Languages.getValue('infoMsgTxt');
      case "warning":
        return Languages.getValue('warningMsgTxt');
      case "success":
        return Languages.getValue('successMsgTxt');
      default:
        break;
    }
  }

  const modalType = (type) => {
    switch (type) {
      case "error":
        return "danger";
      case "info":
        return "info";
      case "warning":
        return "warning";
      case "success":
        return "success";
      default:
        break;
    }
  }

  return (
    <CModal
      show={!!props.message}
      centered={true}
      color={modalType(props.type)}
    >
      {props.type && (
        <CModalHeader closeButton className="modal-header">{messageType(props.type)}</CModalHeader>
      )}
      <CModalBody className="text-center">
        {props.message}
        <div className="mt-3 text-right">
          {props.isActionExist && (
            <CButton color="primary" onClick={props.modalActionFunc}>{props.actionText}</CButton>
          )}
          {props.isShowCancel && (
            <CButton
              color="danger"
              onClick={toggle}
            >{Languages.getValue('cancelMsgTxt')}</CButton>
          )}
        </div>
      </CModalBody>
    </CModal>
  )
}

export default Modal;
