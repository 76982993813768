import { documentConstants } from "../constants/document";

const initialState = {
  items: [],
  updateDocument: {},
  error: "",
  dataSpaceRemain: 0,
  loading: false,
};

export function document(state = initialState, action) {
  switch (action.type) {
    case documentConstants.GET_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
        items: [],
      };
    case documentConstants.GET_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.document,
        error: "",
      };

    case documentConstants.GET_DOCUMENT_FAILURE:
      return {
        ...state,
        items: [],
        loading: false,
        error: action.document,
      };
    case documentConstants.GET_DOCUMENT_REMAIN_SPACE_REQUEST:
      return {
        ...state,
        items: [],
        loading: true,
        dataSpaceRemain: 0,
      };
    case documentConstants.GET_DOCUMENT_REMAIN_SPACE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: [],
        dataSpaceRemain: action.document,
      };
    case documentConstants.GET_DOCUMENT_REMAIN_SPACE_FAILURE:
      return {
        ...state,
        items: [],
        loading: false,
        dataSpaceRemain: action.document,
      };
    case documentConstants.ADD_DOCUMENT_REQUEST:
      return {
        ...state,
        items: [],
        loading: true,
      };
    case documentConstants.ADD_DOCUMENT_SUCCESS:
      return {
        ...state,
        items: [],
        loading: false,
        addDocument: action.addDocument,
      };
    case documentConstants.ADD_DOCUMENT_FAILURE:
      return {
        ...state,
        items: [],
        loading: false,
        error: action.addDocument,
      };

    case documentConstants.UPDATE_DOCUMENT_REQUEST:
      return {
        ...state,
        updateDocument: {},
        loading: true,
      };
    case documentConstants.UPDATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        updateDocument: action.updateDocument,
        error: "",
      };
    case documentConstants.UPDATE_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        updateDocument: {},
        error: action.updateDocument,
      };

    case documentConstants.DELETE_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case documentConstants.DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case documentConstants.DELETE_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.deleteDocument,
      };
    default:
      return state;
  }
}
