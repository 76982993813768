import { feedbackConstants } from '../constants';

const initialState = {
  items: [],
  detailedFeedback: {},
  error: ''
}

export function feedbacks(state = initialState, action) {
  switch (action.type) {
    case feedbackConstants.GET_FEEDBACKS_REQUEST:
      return {
        ...state,
        loading: true,
        detailedFeedback: {}
      };
    case feedbackConstants.GET_FEEDBACKS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        detailedFeedback: {},
        items: action.feedbacks
      };
    case feedbackConstants.GET_FEEDBACKS_FAILURE:
      return {
        ...state,
        loading: false,
        items: [],
        detailedFeedback: {},
        error: action.error
      };
    case feedbackConstants.FEEDBACK_APPROVE_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case feedbackConstants.FEEDBACK_APPROVE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case feedbackConstants.FEEDBACK_APPROVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case feedbackConstants.FEEDBACK_REJECT_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case feedbackConstants.FEEDBACK_REJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case feedbackConstants.FEEDBACK_REJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state
  }
}
