import { authHeader } from '../helpers';
import { userService } from './index';
import Resources from '../libraries/resources';

const apiBaseURL = Resources.getValue("apiBaseEnvURL");

export const feedbackService = {
  getAllFeedbacks,
  approveFeedback,
  rejectFeedback
};

function getAllFeedbacks() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue('getFeedbackListApiURL')}`,
    requestOptions
  ).then(handleResponse);
}

function approveFeedback(approveData) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue('approveFeedbackApiUrl')}?ObjectId=${approveData.objectId}&TaskId=${approveData.taskId}`,
    requestOptions
  ).then(handleResponse);
}

function rejectFeedback(rejectData) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };

  return fetch(
    `${apiBaseURL}${Resources.getValue('rejectFeedbackApiUrl')}?ObjectId=${rejectData.objectId}&TaskId=${rejectData.taskId}`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
      }

      const error = (data && data.message) || (data && data.errors && data.errors[0]) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
