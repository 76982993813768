import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
} from '@coreui/react';
import Languages from "../libraries/languages";

// images
import ForamindLogoLogo from '../assets/img/foramind-logo-blacktext.png';

import {
  TheHeaderDropdown,
  TheHeaderDropdownLanguage,
} from './index'

const TheHeader = () => {

  // Get logged in admin info
  const loggedInUser = useSelector(state => state.authentication.user);
  const adminRole = loggedInUser && loggedInUser.roleIdList[0];

  const dispatch = useDispatch();
  const sidebarShow = useSelector(state => state.sidebarShow);

  const toggleSidebar = () => {
    const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive'
    dispatch({ type: 'set', sidebarShow: val })
  }

  const toggleSidebarMobile = () => {
    const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive'
    dispatch({ type: 'set', sidebarShow: val })
  }

  return (
    <CHeader withSubheader>
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebar}
      />
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        <img src={ForamindLogoLogo} alt="Foramind" className="c-sidebar-brand-minimized" height={50} />
      </CHeaderBrand>

      <CHeaderNav className="d-md-down-none mr-auto">
        <CHeaderNavItem className="px-3" >
          <CHeaderNavLink to="/dashboard">{Languages.getValue('homepageMsgTxt')}</CHeaderNavLink>
        </CHeaderNavItem>
        <CHeaderNavItem className="px-3">
          <CHeaderNavLink to="/users">{Languages.getValue('usersMsgTxt')}</CHeaderNavLink>
        </CHeaderNavItem>
        {(adminRole && adminRole === 3) && (
          <>
            <CHeaderNavItem className="px-3">
              <CHeaderNavLink to="/corporations">{Languages.getValue('corporationsMsgTxt')}</CHeaderNavLink>
            </CHeaderNavItem>
          </>
        )}
      </CHeaderNav>

      <CHeaderNav className="px-3">
        <TheHeaderDropdownLanguage />
        <TheHeaderDropdown />
      </CHeaderNav>
    </CHeader>
  )
}

export default TheHeader;
