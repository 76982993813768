export const userConstants = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT_REQUEST: "USERS_LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "USERS_LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "USERS_LOGOUT_FAILURE",
  LOGOUT: "USERS_LOGOUT",

  GETALL_USERS_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_USERS_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_USERS_FAILURE: "USERS_GETALL_FAILURE",

  GET_ALL_USER_TYPES_REQUEST: "GET_ALL_USER_TYPES_REQUEST",
  GET_ALL_USER_TYPES_SUCCESS: "GET_ALL_USER_TYPES_SUCCESS",
  GET_ALL_USER_TYPES_FAILURE: "GET_ALL_USER_TYPES_FAILURE",

  ADD_USER_REQUEST: "ADD_USER_REQUEST",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ADD_USER_FAILURE: "ADD_USER_FAILURE",

  ADD_USER_TO_COMPANY_REQUEST: "ADD_USER_TO_COMPANY_REQUEST",
  ADD_USER_TO_COMPANY_SUCCESS: "ADD_USER_TO_COMPANY_SUCCESS",
  ADD_USER_TO_COMPANY_FAILURE: "ADD_USER_TO_COMPANY_FAILURE",

  UPDATE_USER_TO_TRIAL_DAY_REQUEST: "UPDATE_USER_TO_TRIAL_DAY_REQUEST",
  UPDATE_USER_TO_TRIAL_DAY_SUCCESS: "UPDATE_USER_TO_TRIAL_DAY_SUCCESS",
  UPDATE_USER_TO_TRIAL_DAY_FAILURE: "UPDATE_USER_TO_TRIAL_DAY_FAILURE",

  GET_USER_REQUEST: "USER_GET_REQUEST",
  GET_USER_SUCCESS: "USER_GET_SUCCESS",
  GET_USER_FAILURE: "USER_GET_FAILURE",

  ACTIVE_PASSIVE_REQUEST: "USER_ACTIVE_PASSIVE_REQUEST",
  ACTIVE_PASSIVE_SUCCESS: "USER_ACTIVE_PASSIVE_SUCCESS",
  ACTIVE_PASSIVE_FAILURE: "USER_ACTIVE_PASSIVE_FAILURE",

  USER_UPDATE_REQUEST: "USER_UPDATE_REQUEST",
  USER_UPDATE_SUCCESS: "USER_UPDATE_SUCCESS",
  USER_UPDATE_FAILURE: "USER_UPDATE_FAILURE",

  USER_CONFIRM_PROFILE_REQUEST: "USER_CONFIRM_PROFILE_REQUEST",
  USER_CONFIRM_PROFILE_SUCCESS: "USER_CONFIRM_PROFILE_SUCCESS",
  USER_CONFIRM_PROFILE_FAILURE: "USER_CONFIRM_PROFILE_FAILURE",

  USER_FORGOT_PASSWORD_REQUEST: "USER_FORGOT_PASSWORD_REQUEST",
  USER_FORGOT_PASSWORD_SUCCESS: "USER_FORGOT_PASSWORD_SUCCESS",
  USER_FORGOT_PASSWORD_FAILURE: "USER_FORGOT_PASSWORD_FAILURE",
};
