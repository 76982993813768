import { blogConstants } from '../constants';

const initialState = {
  items: [],
  detailedBlog: {},
  error: ''
}

export function blogs(state = initialState, action) {
  switch (action.type) {
    case blogConstants.GETALL_BLOGS_REQUEST:
      return {
        ...state,
        loading: true,
        detailedBlog: {}
      };
    case blogConstants.GETALL_BLOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        detailedBlog: {},
        error: '',
        items: action.blogs
      };
    case blogConstants.GETALL_BLOGS_FAILURE:
      return {
        ...state,
        loading: false,
        items: [],
        detailedBlog: {},
        error: action.error
      };
    case blogConstants.BLOG_ADD_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case blogConstants.BLOG_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ''
      };
    case blogConstants.BLOG_ADD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case blogConstants.GET_BLOG_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        detailedBlog: {},
        error: ''
      };
    case blogConstants.GET_BLOG_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        detailedBlog: action.detailedBlog,
        error: ''
      };
    case blogConstants.GET_BLOG_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        detailedBlog: {}
      };
    case blogConstants.BLOG_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case blogConstants.BLOG_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        detailedBlog: action.updatedBlog.data
      };
    case blogConstants.BLOG_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case blogConstants.ACTIVE_PASSIVE_BLOG_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case blogConstants.ACTIVE_PASSIVE_BLOG_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ''
      };
    case blogConstants.ACTIVE_PASSIVE_BLOG_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state
  }
}
