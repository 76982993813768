import React from 'react';
import { useHistory } from 'react-router-dom'
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import CIcon from '@coreui/icons-react';
import Languages from "../libraries/languages";

import { useDispatch, useSelector } from 'react-redux';

// Actions
import { userActions } from '../actions';

const TheHeaderDropdown = () => {

  const history = useHistory();
  const dispatch = useDispatch();

  const loggedInUser = useSelector(state => state.authentication.user);
  const adminRole = loggedInUser && loggedInUser.roleIdList[0];

  const logout = () => {
    dispatch(userActions.logout());
  }

  const goToUpdatePage = () => {
    history.push('/corporation-update');
  }

  const goToProfile = () => {
    history.push('/profile');
  }

  const goToChangePassword = () => {
    history.push('/change-password');
  }

  return (
    <CDropdown
      inNav
      className="c-header-nav-items mx-2"
      direction="down"
    >
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar profile-dropdown-item">
          <CIcon name="cil-user" />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem
          header
          tag="div"
          color="light"
          className="text-center"
        >
          <strong>{Languages.getValue('settingsMsgTxt')}</strong>
        </CDropdownItem>
        {(adminRole === 1) && (
          <CDropdownItem onClick={goToUpdatePage}>
            <CIcon name="cil-settings" className="mfe-2" /> {Languages.getValue('updateCorporationInfosMsgTxt')}
          </CDropdownItem>
        )}
        <CDropdownItem onClick={goToProfile}>
          <CIcon name="cil-settings" className="mfe-2" /> {Languages.getValue('profileMsgTxt')}
        </CDropdownItem>
        <CDropdownItem onClick={goToChangePassword}>
          <CIcon name="cil-settings" className="mfe-2" /> {Languages.getValue('changePasswordMsgTxt')}
        </CDropdownItem>
        <CDropdownItem divider />
        <CDropdownItem onClick={logout}>
          <CIcon name="cil-lock-locked" className="mfe-2" />
          {Languages.getValue('logoutMsgTxt')}
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default TheHeaderDropdown
