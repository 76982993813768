import { profileConstants } from '../constants';

const initialState = {
  profileInfos: {},
  error: ''
}

export function profile(state = initialState, action) {
  switch (action.type) {
    case profileConstants.GET_PROFILE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case profileConstants.GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profileInfos: action.profileInfos,
        error: ''
      };
    case profileConstants.GET_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        profileInfos: {}
      };
    case profileConstants.PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case profileConstants.PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ''
      };
    case profileConstants.PROFILE_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case profileConstants.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case profileConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ''
      };
    case profileConstants.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state
  }
}
