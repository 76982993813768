export const blogConstants = {
  GETALL_BLOGS_REQUEST: 'BLOGS_GETALL_REQUEST',
  GETALL_BLOGS_SUCCESS: 'BLOGS_GETALL_SUCCESS',
  GETALL_BLOGS_FAILURE: 'BLOGS_GETALL_FAILURE',

  GET_BLOG_DETAIL_REQUEST: 'BLOG_DETAIL_GET_REQUEST',
  GET_BLOG_DETAIL_SUCCESS: 'BLOG_DETAIL_GET_SUCCESS',
  GET_BLOG_DETAIL_FAILURE: 'BLOG_DETAIL_GET_FAILURE',

  BLOG_UPDATE_REQUEST: 'BLOG_UPDATE_REQUEST',
  BLOG_UPDATE_SUCCESS: 'BLOG_UPDATE_SUCCESS',
  BLOG_UPDATE_FAILURE: 'BLOG_UPDATE_FAILURE',

  ACTIVE_PASSIVE_BLOG_REQUEST: 'BLOG_ACTIVE_PASSIVE_REQUEST',
  ACTIVE_PASSIVE_BLOG_SUCCESS: 'BLOG_ACTIVE_PASSIVE_SUCCESS',
  ACTIVE_PASSIVE_BLOG_FAILURE: 'BLOG_ACTIVE_PASSIVE_FAILURE',

  BLOG_ADD_REQUEST: 'BLOG_ADD_REQUEST',
  BLOG_ADD_SUCCESS: 'BLOG_ADD_SUCCESS',
  BLOG_ADD_FAILURE: 'BLOG_ADD_FAILURE'
};
